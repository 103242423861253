import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const UsageBar = (props) => {
	const user = useSelector( state => state.user );

	const [ usage, setUsage ] = useState( false );

	useEffect( () => {
		if ( user ) {
			if ( user?.type === '1' ) {
				setUsage( user?.usage?.characters )
			} else {
				setUsage( user?.org?.usage?.characters )
			}
		}
	}, [ user ] );

	const getUsagePercentage = () => {
		if ( usage?.limit <= usage?.spent ) {
			return '100%'
		}

		return `${ ( ( usage?.spent / usage?.limit) * 100 ) }%`
	}

	if ( ! user || ! usage ) {
		return ''
	}

	return (
		<div className="editor-usage">
			<div className="editor-usage__bar">
				<span className="editor-usage__bar-text"><DriveFileRenameOutlineIcon />{ props.t( 'Used' ) } { usage?.spent.toLocaleString() } { props.t( 'out of' ) } { usage?.limit == -1 ? props.t( 'unlimited' ) : usage?.limit.toLocaleString() } { props.t( 'characters' ) }</span>
				<div className="editor-usage__bar-progress" style={{ width: getUsagePercentage() }}></div>
			</div>
		</div>
	)
}

export default UsageBar;
