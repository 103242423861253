import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { APP_ROOT, STRIPE } from '../utils/constants';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';

import greetingTime from 'greeting-time';

const Dashboard = ( props ) => {
	window.location.href = `${APP_ROOT}/projects`;

	useEffect(() => {
		props.setAppClasses( 'dashboard' )
	}, []);

	const user = useSelector( state => state.user );

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': `${ props.t( greetingTime( new Date() ) ) }, <span>${ ( user?.metadata?.contact_person?.name?.split(' ')[0] ?? user?.metadata?.contact_person?.name ) }!</span>`,
						'editable': false
					}}
					description={{
						'value': props.t("Here's an overview of all your activities"),
						'editable': false,
					}}
				/>
			</div>
		</>
	)
}

export default Dashboard;
