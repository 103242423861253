import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import debounce from 'lodash/debounce';

import CreateProjectModal from '../components/modals/CreateProjectModal';
import UsageBar from '../components/editor/UsageBar';

import updateProject from '../api/project/update-project';
import updateContent from '../api/content/update-content';

const TopPanel = ( props ) => {
	const dispatch = useDispatch();
	let project = useSelector( state => state.project );
	let content = useSelector( state => state.content );

	const [ title, setTitle ]             = useState( '' );
	const [ description, setDescription ] = useState( props?.description?.value ?? '' );

	useEffect(() => {
		setTitle( props?.title?.value )
		setDescription( props?.description?.value )
	}, [ props?.title?.value, props?.description?.value ] )

	const handleFieldChange = ( event ) => {
		if ( event.target.name === 'title' ) {
			setTitle( event.target.value );
			save( event.target.value, description );
		}

		if ( event.target.name === 'description' ) {
			setDescription( event.target.value );
			save( title, event.target.value );
		}
	}

	const save = useMemo( () => debounce( (title, description) => {
		if ( props?.isProject && project ) {
			let data =  { ...project,
				title: title,
				description: description
			}

			const updateProjectThunk = updateProject( data )
			dispatch( updateProjectThunk )
		}

		if ( props?.isContent && content ) {
			let data = { ...content,
				title: title,
				description: description
			};

			const updateContentThunk = updateContent( data )
			dispatch( updateContentThunk )
		}
	}, 1000 ), []);

	return (
		<>
			<div className="top-panel">
				<div className="top-panel__page">
					{ props?.title?.editable ? (
						title !== false && (
							<div className={`top-panel__page-title ${ props.title.editable ? 'is-editable' : ''}`}>
								<input
									type="text"
									placeholder="A title..."
									name="title"
									value={ title }
									onChange={ event => {
										handleFieldChange( event )
									} }
								/>
							</div>
						)
					) : (
						<div className={`top-panel__page-title ${ props?.title?.editable ? 'is-editable' : ''}`} dangerouslySetInnerHTML={{ __html: props.title.value }} />
					)}

					{ props?.description?.editable ? (
						description !== false && (
							<div className={`top-panel__page-description ${ props.description.editable ? 'is-editable' : ''}`}>
								<input
									type="text"
									placeholder="A description..."
									name="description"
									value={ description }
									onChange={ event => {
										handleFieldChange( event )
									} }
								/>
							</div>
						)
					) : (
						<div className={`top-panel__page-description ${ props?.description?.editable ? 'is-editable' : ''}`} dangerouslySetInnerHTML={{ __html: props?.description?.value }} />
					)}
				</div>

				{ props.isProject && (
					<CreateProjectModal {...props} />
				)}

				{ props.isContent && (
					<UsageBar {...props} />
				)}
			</div>
		</>
	)
}

export default TopPanel;
