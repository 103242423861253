import { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import {  GOOGLE_CLIENT_ID } from '../utils/constants';
import TextField from '@mui/material/TextField';
import { PrimaryButton, PrimaryButtonDisabled } from '../components/buttons/PrimaryButton';
import { SecondaryButton } from '../components/buttons/SecondaryButton';
import logo from '../assets/dist/img/birdie.png';

import loginUser from '../api/user/login-user';

const Login = ( props ) => {
	useEffect(() => {
		props.setAppClasses( 'login' )
	}, []);

	const dispatch                        = useDispatch();
	const loading                         = useSelector(state => state.loading);
	const [email, setEmail]               = useState( '' );
	const [password, setPassword]         = useState( '' );

	const onSubmit = ( event ) => {
		event.preventDefault();

		const auth = getAuth();
		signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			// Signed in
			const user = userCredential.user;

			if ( user ) {
				const loginUserThunk = loginUser({
					'email': email,
					'password': password,
				})

				dispatch(loginUserThunk)
			}
		})
		.catch((error) => {
			handleErrors( error )
		});
	}

	const handleGoogleLogin = () => {
		const auth = getAuth();
		var provider = new GoogleAuthProvider();

		signInWithPopup(auth, provider)
		.then((result) => {
			// This gives you a Google Access Token. You can use it to access the Google API.
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const token = credential.accessToken;
			// The signed-in user info.
			const user = result.user;

			const loginUserThunk = loginUser({
				'email': user?.email,
				'uid': user?.uid,
				'method': 'google'
			})

			dispatch(loginUserThunk)
		}).catch((error) => {
			handleErrors( error )
		});
	}

	const handleErrors = ( error ) => {
		const errorCode = error.code;

		if ( errorCode === 'auth/user-not-found' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Woops! We did not find any user matching the entered email.' } })
		} else if ( errorCode === 'auth/invalid-email' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Seems like the entered email is invalid.' } })
		} else if ( errorCode === 'auth/wrong-password' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Woops! Wrong password.' } })
		} else if ( errorCode === 'auth/too-many-requests' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Too many requests. Try again later' } })
		} else {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An unknown error occured. Reach out for more information.' } })
		}
	}


	let button = '';
	if ( email.includes( '@' ) && email.includes( '.' ) && password !== '' ) {
		button = <PrimaryButton text="Fortsæt" type="submit" />
	} else {
		button = <PrimaryButtonDisabled text="Fortsæt" />
	}

	return (
		<>
			<div className="login__logo">
				<img src={ logo } alt="logo" />
			</div>
			<span className="login__login">
				Ikke oprettet endnu? <NavLink to="/sign-up">Opret dig her</NavLink>
			</span>
			<div className="login__container login__container-login" disabled={ loading }>
				<div className="login__title">
					Log ind
				</div>
				<form
					className="login__form form"
					onSubmit={ onSubmit }
				>
					<div className="form__fields">
						<TextField
							label="E-mail"
							defaultValue=""
							variant="outlined"
							autoComplete="new-email"
							onChange={ event => setEmail( event.target.value ) }
						/>
						<TextField
							label="Adgangskode"
							type="password"
							onChange={ event => setPassword( event.target.value ) }
							variant="outlined"
							autoComplete="new-password"
						/>
						{ button }
					</div>
				</form>
				<div className="login__separator">
					<span />
					<span className="login__separator-text">eller</span>
					<span />
				</div>
				<SecondaryButton style={{ width: '100%' }} onClick={handleGoogleLogin} text={ props.t( 'Fortsæt med Google' ) } />
			</div>

			<div className="login__footer">
				<nav>
					<ul>
						<li>&copy; Straact 2022</li>
						<li><a href="https://straact.dk/betingelser" target="_blank">{ props.t( 'Betingelser' ) }</a></li>
					</ul>
				</nav>
			</div>
		</>
	)
}

export default Login;
