import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const RouteChange = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [previousPath, setPreviousPath] = useState( location?.pathname ?? '' )

	useEffect(() => {
		// Clear content state
		if ( previousPath.includes( '/editor/' ) ) {
			dispatch({ type: 'GET_CONTENT', payload: false })
		}

		// Clear project(s) and content states
		if ( previousPath.includes( '/projects/' ) ) {
			dispatch({ type: 'GET_ASSOCIATED_CONTENT', payload: false })
			dispatch({ type: 'GET_ASSOCIATED_PROJECTS', payload: false })
			dispatch({ type: 'GET_PROJECT', payload: false })
		}

		setPreviousPath( location?.pathname )
	}, [ location ]);

	return ''
}

export default RouteChange;