import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ShortTextIcon from '@mui/icons-material/ShortText';
import SubjectIcon from '@mui/icons-material/Subject';
import Tooltip from '@mui/material/Tooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Zoom from '@mui/material/Zoom';

import { getPredefinedTemplates } from "./predefined-templates"
import { getCustomTemplates } from './custom-templates';
import updateContent from '../../api/content/update-content';
import { SecondaryButton, SecondaryButtonDisabled } from '../../components/buttons/SecondaryButton';

const getTemplate = ( value ) => {
	let predefinedTemplates = getPredefinedTemplates()
	let customTemplates = getCustomTemplates()

	let templates = predefinedTemplates.concat(customTemplates);

	return templates.find( ( template ) => template.value === value )
}

const getField = ( dispatch, content, template, field ) => {
	function handleFieldChange( value ) {
		let template_field_values = content?.templates[template?.value] ?? {};
		template_field_values[field?.id] = value

		let data = { ...content,
			templates: {...content?.templates,
				current_value: template?.value,
				[template?.value]: template_field_values
			},
			active_tab: 1,
		}

		dispatch({ type: 'GET_CONTENT', payload: data })

		// const updateContentThunk = updateContent( data )
		// dispatch( updateContentThunk )
	}

	let defaultValue = '';

	if ( field?.value && field?.value !== '' ) {
		defaultValue = field?.value;
	}

	console.log('field', field)
	console.log('defaultValue', defaultValue)
	console.log('content?.templates[template?.value]', content?.templates[template?.value])

	if ( field?.type === 'text_input' ) {
		return (
			<Box className={ `template-form__field__text-wrapper ${ field?.id }` }>
				<FormControl fullWidth>
					<InputLabel id={ `${ field?.id }-label` } className={ `${ field?.tooltip ? 'has-tooltip' : ''}` } shrink={ true }>
						{ field?.label }
						{ field?.tooltip && (
							<Tooltip title={ field?.tooltip } placement="top" TransitionComponent={Zoom}>
								<HelpRoundedIcon />
							</Tooltip>
						)}
					</InputLabel>
					<TextField
						variant="outlined"
						defaultValue={ defaultValue }
						placeholder={ field?.placeholder ?? '' }
						autoComplete="new-name"
						onChange={ ( event ) => handleFieldChange( event.target.value ) }
						helperText={ field?.helper_text }
					/>
				</FormControl>
			</Box>
		)
	}

	if ( field?.type === 'textarea' ) {
		return (
			<Box className={ `template-form__field__text-wrapper ${ field?.id }` }>
				<FormControl fullWidth>
					<InputLabel id={ `${ field?.id }-label` } className={ `${ field?.tooltip ? 'has-tooltip' : ''}` } shrink={ true }>
						{ field?.label }
						{ field?.tooltip && (
							<Tooltip title={ field?.tooltip } placement="top" TransitionComponent={Zoom}>
								<HelpRoundedIcon />
							</Tooltip>
						)}
					</InputLabel>
					<TextField
						variant="outlined"
						defaultValue={ defaultValue }
						multiline
						minRows={3}
						placeholder={ field?.placeholder ?? '' }
						maxRows={8}
						autoComplete="new-name"
						helperText={ field?.helper_text }
						onChange={ ( event ) => handleFieldChange( event.target.value ) }
						className={ `template-form__field__textarea-wrapper ${ field?.id }` }
					/>
				</FormControl>
			</Box>
		)
	}

	if ( field?.type === 'select' ) {
		return (
			<Box className={ `template-form__field__select-wrapper ${ field?.id }` }>
				<FormControl fullWidth>
					<InputLabel id={ `${ field?.id }-label` } className={ `${ field?.tooltip ? 'has-tooltip' : ''}` } shrink={ true }>
						{ field?.label }
						{ field?.tooltip && (
							<Tooltip title={ field?.tooltip } placement="top" TransitionComponent={Zoom}>
								<HelpRoundedIcon />
							</Tooltip>
						)}
					</InputLabel>
					<Select
						id={ `${ field?.id }-select` }
						value={ defaultValue }
						label={ field?.label }
						placeholder={ field?.placeholder ?? '' }
						onChange={ ( event ) => handleFieldChange( event.target.value ) }
					>
						{ field?.items && field?.items.map( ( item ) => {
							return (
								<MenuItem key={ item?.value } value={ item?.value }>{ item?.label }</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</Box>
		)
	}

	if ( field?.type === 'button_group' ) {
		return (
			<ButtonGroup variant="outlined" className={ `template-form__field__button-group-wrapper ${ field?.id }` }>
				{ field?.items && field?.items.map( ( item ) => {
					return (
						<SecondaryButton icon={ item?.icon } size="small" key={ item?.value } value={ item?.value } text={ item?.label } />
					)
				})}
			</ButtonGroup>
		)
	}

	if ( field?.type === 'slider' ) {

		return (
			<Box className={ `template-form__field__slider-wrapper ${ field?.id }` }>
				<Typography id={ `${ field?.id }-label` } gutterBottom>
					{ field?.label }
				</Typography>
				<Box style={{ padding: '0 var(--spacing-4)'}}>
					<Slider
						defaultValue={ 1 }
						step={ null }
						marks={ field?.items }
						onChange={ ( event ) => handleFieldChange( event.target.value ) }
					/>
				</Box>
			</Box>
		)
	}

	return ''
}

export {
	getTemplate,
	getField
}
