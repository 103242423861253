import axios from 'axios';
import { API_ENDPOINTS, ACCESS_TOKEN } from '../../utils/constants';

const inviteMember = ( params, setOpen ) => {
	return async function inviteMemberThunk( dispatch ) {
		dispatch({ type: 'SET_IS_LOADING', payload: true })

		axios.post(
			`${API_ENDPOINTS.STRAACT_FLASK_API}/member/invite`,
			params,
			{
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		)
		.then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					setOpen( false )
					dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'We have sent an e-mail invite to your colleague!' } })
				}

				if ( response.data.type === 'error' ) {
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
				}
			}

		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})

		dispatch({ type: 'SET_IS_LOADING', payload: false })
	}
}

export default inviteMember;
