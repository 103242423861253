const contentReducer = ( state = false, action ) => {
	switch( action.type ) {
		case 'GET_CONTENT' :
			return action.payload
		default:
			return state
	}
}

export default contentReducer;
