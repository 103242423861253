import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { APP_ROOT } from '../utils/constants';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';

const Analytics = ( props ) => {
	window.location.href = `${APP_ROOT}/projects`;

	const user = useSelector( state => state.user );

	useEffect(() => {
		props.setAppClasses( 'analytics' )
	}, []);

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Analytics' ),
						'editable': false
					}}
				/>
			</div>
		</>
	)
}

export default Analytics;
