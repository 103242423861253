import { useSelector, useDispatch } from 'react-redux';
import { getDateBeauty } from '../../utils/date';

import CancelSubscriptionModal from '../modals/CancelSubscriptionModal';
import { PrimaryButton } from '../buttons/PrimaryButton';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import reactivateSubscription from '../../api/stripe/reactivate-subscription';

const Usage = (props) => {
	const user                = useSelector( state => state.user );
	const stripe_subscription = useSelector( state => state.stripe_subscription );

	if ( ! user ) {
		return ''
	}

	const getUsagePercentage = () => {
		if ( user?.usage?.characters?.limit <= user?.usage?.characters?.spent ) {
			return '100%'
		}

		return `${ ( ( user?.usage?.characters?.spent / user?.usage?.characters?.limit) * 100 ) }%`
	}

	const handleReactivateSubscription = () => {
		reactivateSubscription(
			{
				'user': user,
			}
		)
	}

	return (
		<div className="settings-usage">
			<div className="settings-usage__title">
				{ props.t( 'Usage' ) }
			</div>
			<div className="settings-usage__bar">
				<span className="settings-usage__bar-text"><DriveFileRenameOutlineIcon />{ props.t( 'Used' ) } { user?.usage?.characters?.spent.toLocaleString() } { props.t( 'out of' ) } { user?.usage?.characters?.limit == -1 ? props.t( 'unlimited' ) : user?.usage?.characters?.limit.toLocaleString() } { props.t( 'characters' ) }</span>
				<div className="settings-usage__bar-progress" style={{ width: getUsagePercentage() }}></div>
			</div>

			{ stripe_subscription && user?.plan !== '0' && (
				<div className="settings-usage__refill-date">
					{ props.t( 'Your monthly usage limit will reset on:' ) }
					{` ${ getDateBeauty( stripe_subscription?.billing_cycle_anchor ) }`}
				</div>
			)}

			{ stripe_subscription && user?.plan !== '0' && (
					<>
						{ stripe_subscription?.cancel_at_period_end !== true ? (
							<CancelSubscriptionModal {...props} />
						) : (
							<PrimaryButton
								className="reactivate-subscription__btn"
								icon={ <ElectricBoltIcon /> }
								text={ props.t( 'Reactivate my subscription' ) }
								size="small"
								onClick={ handleReactivateSubscription }
							/>
						)}
					</>
				)}
		</div>
	)
}

export default Usage;
