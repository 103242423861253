import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';
import upgradeUser from '../api/user/upgrade-user';
import getSubscription from '../api/stripe/get-subscription';
import InvoiceList from '../components/billing/InvoiceList';

const Billing = ( props ) => {
	const dispatch = useDispatch();
	const user = useSelector( state => state.user );
	const stripe_subscription = useSelector( state => state?.stripe_subscription );
	const { response, session_id } = useParams()

	useEffect(() => {
		props.setAppClasses( 'billing' )
	}, []);

	useEffect(() => {
		if ( response && response === 'subscription_confirmed' && session_id ) {
			const upgradeUserThunk = upgradeUser( session_id )
			if ( upgradeUserThunk ) {
				dispatch(upgradeUserThunk)
			}
		}
	}, [ response, session_id ]);

	useEffect(() => {
		if ( ! response && ! session_id && ! stripe_subscription && user?.metadata?.customer_id ) {
			const getSubscriptionThunk = getSubscription(user?.metadata?.customer_id)
			if ( getSubscriptionThunk ) {
				dispatch(getSubscriptionThunk)
			}
		}
	}, [ stripe_subscription, user ]);

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Billing' ),
						'editable': false
					}}
					description={{
						'value': props.t( 'An overview of all your invoices and payment info' ),
						'editable': false
					}}
				/>

				<InvoiceList { ...props } />
			</div>
		</>
	)
}

export default Billing;
