import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const getSubscription = ( customer_id ) => {
	if ( UID === undefined && ACCESS_TOKEN === undefined ) {
		return false;
	}

	return async function getSubscriptionThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/stripe/subscription/get`,
			{
				params: {
					uid: UID,
					customer_id: customer_id,
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_SUBSCRIPTION', payload: response.data.subscription })
				}
			}
		})
	}
}

export default getSubscription;
