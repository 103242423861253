import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import LeftPanel from '../../layouts/LeftPanel';
import TopPanel from '../../layouts/TopPanel';

import TextField from '@mui/material/TextField';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';

import { PrimaryButton } from '../../components/buttons/PrimaryButton';

const ChromeExtensionIntegration = ( props ) => {
	useEffect(() => {
		props.setAppClasses( 'integrations' )
	}, []);

	const user = useSelector( state => state.user );

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Integration with Chrome' ),
						'editable': false
					}}
					description={{
						'value': props.t( 'Something clever should be here' ),
						'editable': false
					}}
				/>

				<div className="settings-sections">
					<div className="settings-sections__item">
						<div className="settings-sections__item-title">{ props.t( 'Status' ) }</div>
					</div>

					<div className="settings-sections__item">
						<div className="settings-sections__item-title">{ props.t( 'Synchronize content' ) }</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ChromeExtensionIntegration;
