import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';
import UsersList from '../components/backoffice/UsersList';

const Backoffice = ( props ) => {
	useEffect(() => {
		props.setAppClasses( 'backoffice' )
	}, []);

	const user = useSelector( state => state.user );

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Backoffice' ),
						'editable': false
					}}
					description={{
						'value': props.t( 'Restricted area for Straact members only' ),
						'editable': false,
					}}
					{ ...props }
				/>

				<UsersList { ...props } />
			</div>
		</>
	)
}

export default Backoffice;
