import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';

const getDateString = ( date ) => {
	return moment.unix( date ).format( 'DD-MM-YYYY' );
}

const getDateBeauty = ( date ) => {
	return moment.unix( date ).format( 'MMM Do YYYY' );
}

export {
	getDateString,
	getDateBeauty
}
