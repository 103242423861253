import axios from 'axios';
import { API_ENDPOINTS, ACCESS_TOKEN } from '../../utils/constants';

const createContent = ( params, setRedirect ) => {
	return async function createContentThunk( dispatch ) {
		dispatch({ type: 'SET_IS_LOADING', payload: true })

		axios.post(
			`${API_ENDPOINTS.STRAACT_FLASK_API}/content/create`,
			params,
			{
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		)
		.then( response => {
			if ( response?.data ) {
				if ( response?.data?.type === 'success' ) {
					setRedirect( '/editor/' + response?.data?.content?.id )
				}

				if ( response.data.type === 'error' ) {
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})

		dispatch({ type: 'SET_IS_LOADING', payload: false })
	}
}

export default createContent;
