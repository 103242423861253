import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { APP_ROOT } from '../utils/constants';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';

import greetingTime from 'greeting-time';

const Error404 = ( props ) => {
	useEffect(() => {
		props.setAppClasses( '404' )
	}, []);

	const user = useSelector( state => state.user );

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t("Woops! 404"),
						'editable': false
					}}
					description={{
						'value': props.t("The page you are looking for doesn't exist."),
						'editable': false,
					}}
				/>
			</div>
		</>
	)
}

export default Error404;
