import Button from '@mui/material/Button';

const PrimaryButtonDisabled = ( props ) => {
    return (
        <>
            <Button
                startIcon={ props.icon }
                variant="contained"
                disabled
                className={ `btn primary-btn ${props.className}` }
            >
                { props.text }
            </Button>
        </>
    )
}

export default PrimaryButtonDisabled;