import axios from 'axios';

import { API_ENDPOINTS, ACCESS_TOKEN } from '../../utils/constants';

const writeContent = ( params ) => {
	return async function writeContentThunk( dispatch ) {
		dispatch({ type: 'SET_IS_LOADING', payload: true })
		dispatch({ type: 'SET_QUERY_RUNNING', payload: true })

		await axios.post(
			`${API_ENDPOINTS.STRAACT_FLASK_API}/content/write`,
			params,
			{
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		)
		.then( response => {
			if ( response?.data ) {
				if ( response?.data?.type === 'success' ) {
					dispatch({ type: 'GET_CONTENT', payload: response.data.content })
					dispatch({ type: 'GET_USER', payload: response.data.user })
				}

				if ( response?.data?.type === 'error' ) {
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})

		dispatch({ type: 'SET_QUERY_RUNNING', payload: false })
		dispatch({ type: 'SET_IS_LOADING', payload: false })
	}
}

export default writeContent;
