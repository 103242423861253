import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { STRIPE } from '../utils/constants';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';
import DocumentList from '../components/projects/DocumentList';

const Projects = ( props ) => {
	useEffect(() => {
		props.setAppClasses( 'projects' )
	}, []);

	const user = useSelector( state => state.user );

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Projects' ),
						'editable': false
					}}
					description={{
						'value': props.t( 'Manage projects and content' ),
						'editable': false,
					}}
					isProject
					createProject={ props.t( 'Create project' ) }
					{ ...props }
				/>

				<DocumentList parent="0" { ...props } />
			</div>
		</>
	)
}

export default Projects;
