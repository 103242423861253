import Cookies from 'js-cookie';

const HOST = window.location.hostname;

const production = {
	'ENV': 'production',
	'SITE_HOST': HOST,
	'APP_ROOT': 'https://app.straact.com',
	'API_ENDPOINTS': {
		'STRAACT_FLASK_API': 'https://api.straact.com/api/v1'
	},
	'STRIPE': {
		'PUBLIC_KEY': 'pk_live_51LJjs3BjmFzzRKV5cIZkljoOwlhTie7stbMqH54leu07F48ILhpdD8Uq0TqrRXte2cghxeP1Y24a3ovYFDHcIwzu00uvRVo6pw',
		'PAYMENT_LINKS': {
			'1_MONTH_STARTER': 'https://buy.stripe.com/eVa3cu56VcSVdC89AJ',
			'1_MONTH_PRO': 'https://buy.stripe.com/9AQfZggPDdWZcy43cm',
			'1_MONTH_PREMIUM': 'https://buy.stripe.com/8wMaEW2YN4mpeGcfZ9',
			'1_MONTH_ENTERPRISE': 'https://buy.stripe.com/5kA14meHvbOR55C4go'
		}
	}
}

const stage = {
	'ENV': 'staging',
	'SITE_HOST': HOST,
	'APP_ROOT': 'https://straact-app-staging.web.app',
	'API_ENDPOINTS': {
		'STRAACT_FLASK_API': 'https://straact-api-staging.web.app/api/v1'
	},
	'STRIPE': {
		'PUBLIC_KEY': 'pk_test_51LJjs3BjmFzzRKV5MwrXDvkkHlqq9deGNyFb43ckv74sZnkFwMu4cnbfKFSywRu8rNLt2yd84ztplcoslm8MLOfh00hU8CRahD',
		'PAYMENT_LINKS': {
			'1_MONTH_STARTER': 'https://buy.stripe.com/test_28oeVcaQBeI18iA14i',
			'1_MONTH_PRO': 'https://buy.stripe.com/test_6oEeVcgaV43n7ewfZa',
			'1_MONTH_PREMIUM': 'https://buy.stripe.com/test_5kAdR8f6R8jDgP69AN',
			'1_MONTH_ENTERPRISE': 'https://buy.stripe.com/test_aEUdR89Mx2Zj0Q85ku'
		}
	}
}

const local = {
	'ENV': 'local',
	'SITE_HOST': HOST,
	'APP_ROOT': 'http://localhost:3000',
	'API_ENDPOINTS': {
		'STRAACT_FLASK_API': 'http://127.0.0.1:5050/api/v1'
	},
	'STRIPE': {
		'PUBLIC_KEY': 'pk_test_51LJjs3BjmFzzRKV5MwrXDvkkHlqq9deGNyFb43ckv74sZnkFwMu4cnbfKFSywRu8rNLt2yd84ztplcoslm8MLOfh00hU8CRahD',
		'PAYMENT_LINKS': {
			'1_MONTH_STARTER': 'https://buy.stripe.com/test_28oeVcaQBeI18iA14i',
			'1_MONTH_PRO': 'https://buy.stripe.com/test_6oEeVcgaV43n7ewfZa',
			'1_MONTH_PREMIUM': 'https://buy.stripe.com/test_5kAdR8f6R8jDgP69AN',
			'1_MONTH_ENTERPRISE': 'https://buy.stripe.com/test_aEUdR89Mx2Zj0Q85ku'
		}
	}
}

const getConstant = ( name ) => {
	switch( HOST ) {
		case 'localhost':
			return local[ name ];
		case 'straact-app-staging.web.app':
				return stage[ name ];

		default:
			return production[ name ];
	}
}

export const ENV              = getConstant( 'ENV' );
export const SITE_HOST        = getConstant( 'SITE_HOST' );
export const APP_ROOT         = getConstant( 'APP_ROOT' );
export const API_ENDPOINTS    = getConstant( 'API_ENDPOINTS' );
export const STRIPE           = getConstant( 'STRIPE' );
export const GOOGLE_CLIENT_ID = '353929273303-k5qnqqpf4037gsnk088m6jen2rqqu4db.apps.googleusercontent.com';
export const UID              = Cookies.get( 'straact_uid' );
export const ACCESS_TOKEN     = Cookies.get( 'straact_authorization' );
