import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const upgradeUser = ( session_id ) => {
	if ( UID === undefined && ACCESS_TOKEN === undefined ) {
		return false;
	}

	return async function upgradeUserThunk( dispatch ) {
		dispatch({ type: 'SET_IS_LOADING', payload: true })

		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/stripe/subscription/upgrade`,
			{
				params: {
					uid: UID,
					session_id: session_id,
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_USER', payload: response.data.user })
				}
				if ( response.data.type === 'error' ) {
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})

		dispatch({ type: 'SET_IS_LOADING', payload: false })
	}
}

export default upgradeUser;
