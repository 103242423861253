import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { GOOGLE_CLIENT_ID } from '../utils/constants';
import { PasswordController, StrengthIndicator } from '../utils/PasswordController';
import { PrimaryButton, PrimaryButtonDisabled } from '../components/buttons/PrimaryButton';
import { SecondaryButton } from '../components/buttons/SecondaryButton';
import logo from '../assets/dist/img/birdie.png';
import createUser from '../api/user/create-user';
import { GetLocalLanguage } from '../utils/Language';
import SignUpFeatures from '../components/SignUpFeatures';
import countries from '../utils/country';

const SignUp = ( props ) => {
	useEffect(() => {
		props.setAppClasses( 'sign-up' )
	}, []);

	const dispatch                          = useDispatch();
	const loading                           = useSelector(state => state.loading);
	const [email, setEmail]                 = useState( '' );
	const [password, setPassword]           = useState( '' );
	const [websiteUrl, setWebsiteUrl]       = useState( '' );
	const [contactPerson, setContactPerson] = useState( '' );
	const [companyName, setCompanyName]     = useState( '' );
	const [country, setCountry]             = useState( '' );
	const [userID, setUserID]               = useState( '' );
	const [formEvent, setFormEvent]         = useState( false );
	const [secondStep, setSecondStep]       = useState( false );
	const [terms, setTerms]                 = useState( false );
	const [strength, setStrength]           = useState( '' );
	const [urlParams, setUrlParams]         = useState( new URLSearchParams( window.location.search ) )
	const steps = [
		'Start jeres rejse',
		'Færdiggør oprettelse',
	];

	// Check if it is a member sign up
	useEffect(() => {
		if ( urlParams.get( 'member' ) !== null ) {
			if ( urlParams.get( 'email' ) !== null ) {
				setEmail( urlParams.get( 'email' ) )
			}
		}
	}, [ urlParams ]);

	const onSubmit = ( event ) => {
		event.preventDefault();
		setFormEvent( event )

		if ( userID ) {
			insertUserMetadata( event )
		} else {
			triggerCreateUser( event )
		}
	}

	useEffect( () => {
		if ( userID ) {
			setSecondStep( true )
		}
	}, [ userID ])

	useEffect( () => {
		if ( userID && formEvent ) {
			insertUserMetadata( formEvent )
		}
	}, [ userID, formEvent ])


	const triggerCreateUser = ( event = {} ) => {
		// Use Firebase authentication to verify and register the user. Rest of the metadata is saved into the database.
		const auth = getAuth();
		createUserWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			// Signed in
			const user = userCredential.user;

			if ( user ) {
				setUserID( user?.uid )
			}
		})
		.catch((error) => {
			handleErrors( error )
		});
	}

	const insertUserMetadata = ( event = {} ) => {
		const createUserThunk = createUser(
			{
				'uid': userID,
				'email': email,
				'password': password,
				'metadata': {
					'org_id': urlParams.get( 'org' ) ?? false,
					'company_name': companyName,
					'contact_person': {
						'name': contactPerson
					},
					'country': event?.target?.country?.value ?? '',
					'website': {
						'url': websiteUrl
					},
					'lang': GetLocalLanguage()
				}
			},
			setSecondStep
		)

		dispatch(createUserThunk)
	}

	const handleContinueClick = () => {
		if ( urlParams.get( 'member' ) !== null && urlParams.get( 'org' ) !== null ) {
			triggerCreateUser()
		} else {
			setSecondStep( true )
		}
	}

	let buttonContinue = '';
	if ( password !== '' && strength == 'Strong' && email.includes( '@' ) && email.includes( '.' ) ) {
		buttonContinue = <PrimaryButton text="Fortsæt" onClick={ handleContinueClick } />
	} else {
		buttonContinue = <PrimaryButtonDisabled text="Fortsæt" />
	}

	let buttonSignup = '';
	if ( strength == 'Strong' && email.includes( '@' ) && email.includes( '.' ) && terms && contactPerson !== '' && companyName !== '' ) {
		buttonSignup = <PrimaryButton text="Kom i gang" type="submit" />
	} else {
		buttonSignup = <PrimaryButtonDisabled text="Kom i gang" />
	}

	const handleGoogleSignup = () => {
		const auth = getAuth();
		var provider = new GoogleAuthProvider();

		signInWithPopup(auth, provider)
		.then((result) => {
			// This gives you a Google Access Token. You can use it to access the Google API.
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const token = credential.accessToken;
			// The signed-in user info.
			const user = result.user;

			setEmail( user?.email )
			setContactPerson( user?.displayName )
			setStrength( 'Strong' )
			setUserID( user?.uid )
		}).catch((error) => {
			handleErrors( error )
		});
	}

	const handleErrors = ( error ) => {
		const errorCode = error.code;

		if ( errorCode === 'auth/email-already-in-use' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Woops! A user with this email already exists.' } })
		} else if ( errorCode === 'auth/invalid-email' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Seems like the entered email is invalid.' } })
		} else if ( errorCode === 'auth/wrong-password' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Woops! Wrong password.' } })
		} else if ( errorCode === 'auth/too-many-requests' ) {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Too many requests. Try again later' } })
		} else {
			dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An unknown error occured. Reach out for more information.' } })
		}
	}

	return (
		<>
			<SignUpFeatures secondStep={ secondStep } />

			<div className="sign-up__container" disabled={ loading }>
				<span className="sign-up__login">
					Allerede oprettet? <NavLink to="/login">Log ind her</NavLink>
				</span>

				<div className="sign-up__wrapper">
					<div className="sign-up__logo">
						<img src={ logo } alt="logo" />
					</div>
					<Box sx={{ width: '100%' }}>
						<Stepper activeStep={ secondStep ? 1 : 0 } alternativeLabel>
							{steps.map( ( label ) => (
								<Step key={ label }>
									<StepLabel>{ label }</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					<form
						className="sign-up__form form"
						onSubmit={ onSubmit }
					>
						<div className="form__fields">
							{ ! secondStep ? (
								<>
									<TextField
										label="E-mail"
										value={ email }
										variant="outlined"
										autoComplete="new-email"
										key={ 'email-input' }
										onChange={ event => setEmail( event.target.value ) }
									/>
									<TextField
										label="Adgangskode"
										type="password"
										onChange={ event => PasswordController( event.target.value, setStrength, setPassword ) }
										variant="outlined"
										key={ 'password-input' }
										autoComplete="new-password"
									/>
									{
										StrengthIndicator( strength )
									}
								</>
							) : (
								<>
									<TextField
										name="companyName"
										label="Firmanavn"
										variant="outlined"
										autoComplete="new-name"
										key={ 'company-name-input' }
										onChange={ event => setCompanyName( event.target.value ) }
									/>
									<TextField
										name="contactPerson"
										label="Kontaktperson"
										defaultValue={ contactPerson ?? '' }
										variant="outlined"
										autoComplete="new-name"
										key={ 'contact-person-input' }
										onChange={ event => setContactPerson( event.target.value ) }
									/>
									<Autocomplete
										id="country-select"
										options={ countries }
										autoHighlight
										getOptionLabel={ ( option ) => option.label }
										renderOption={ ( props, option ) => (
											<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
												<img
													loading="lazy"
													width="20"
													src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
													srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
													alt=""
												/>
												{ option.label }
											</Box>
										)}
										renderInput={ ( params ) => (
											<TextField
												{...params}
												name="country"
												label={ props.t( 'Vælg et land' ) }
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // disable autocomplete and autofill
												}}
											/>
										)}
									/>
									<TextField
										label={ props.t( 'Hjemmeside' ) }
										variant="outlined"
										autoComplete="new-name"
										InputProps={{
											startAdornment: <InputAdornment position="start">https://</InputAdornment>,
										}}
										name="websiteUrl"
										key={ 'website-url-input' }
										onChange={ event => setWebsiteUrl( event.target.value ) }
									/>
									<FormGroup>
										<FormControlLabel control={
											<Checkbox
												onChange={ event => setTerms( event.target.checked ) }
												required
											/>
										} label="Jeg accepterer betingelserne ved brug af Straact." />
									</FormGroup>
								</>
							)}
							{ ! secondStep ? (
								<>
									{ buttonContinue }
									<div className="sign-up__separator">
										<span />
										<span className="sign-up__separator-text">eller</span>
										<span />
									</div>
									<SecondaryButton onClick={handleGoogleSignup} text={ props.t( 'Fortsæt med Google' ) } />
								</>
							) : (
								buttonSignup
							)}
						</div>
					</form>

					<div className="sign-up__footer">
						<nav>
							<ul>
								<li>&copy; Straact 2022</li>
								<li><a href="https://straact.dk/betingelser" target="_blank">{ props.t( 'Betingelser' ) }</a></li>
							</ul>
						</nav>
					</div>

				</div>
			</div>
		</>
	)
}

export default SignUp;
