import { combineReducers } from 'redux';

import userReducer from './user-reducer';
import editorReducer from './editor-reducer';
import messageReducer from './message-reducer';
import loadingReducer from './loading-reducer';
import backofficeUsersReducer from './backoffice-users-reducer';
import associatedProjectsReducer from './associated-projects-reducer';
import associatedContentReducer from './associated-content-reducer';
import projectReducer from './project-reducer';
import membersReducer from './member-reducer';
import contentReducer from './content-reducer';
import stripeSubscriptionReducer from './stripe-subscription-reducer';
import stripeInvoicesReducer from './stripe-invoices-reducer';
import queryReducer from './query-reducer';

const reducers = combineReducers({
	user: userReducer,
	content: contentReducer,
	project: projectReducer,
	editor: editorReducer,
	members: membersReducer,
	display_message: messageReducer,
	query_running: queryReducer,
	loading: loadingReducer,
	associated_projects: associatedProjectsReducer,
	associated_content: associatedContentReducer,
	stripe_subscription: stripeSubscriptionReducer,
	stripe_invoices: stripeInvoicesReducer,
	backoffice_users: backofficeUsersReducer
});

export default reducers;
