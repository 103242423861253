import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate, useParams, NavLink } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import AddRoundedIcon from '@mui/icons-material/AddRounded';

import logo from '../assets/dist/img/birdie.png';
import Avatar from '../components/user/Avatar';
import ToggleSettings from '../components/user/ToggleSettings';
import { PrimaryButton, PrimaryButtonDisabled } from '../components/buttons/PrimaryButton';
import { planType } from '../utils/plans';

import createContent from '../api/content/create-content';

const LeftPanel = ( props ) => {
	const { minimized } = props;

	const dispatch = useDispatch();
	const user     = useSelector( state => state.user );
	const [ redirect, setRedirect ]         = useState( false );
	const { id }                            = useParams()
	const navigate                          = useNavigate()

	let plan = planType(user?.plan)

	useEffect(() => {
		if ( redirect !== false ) {
			navigate( redirect )
		}
	}, [ redirect ]);

	const onSubmitEditor = async ( event ) => {
		event.preventDefault();

		const createContentThunk = createContent(
			{
				'user': user,
				'title': props.t( 'Unnamed document' ),
				'type': 1,
				'metadata': {},
				'parent': id ?? '0',
				'active_tab': 0,
			},
			setRedirect
		)

		dispatch( createContentThunk )
	}

	return (
		<>
			<div className={`left-panel${ minimized ? ' minimized' : '' }`}>
				<div className="left-panel__top">
					<NavLink to="/">
						<img src={ logo } alt="logo" />
					</NavLink>

					{ ! minimized && user && (
						<>
							<div className="left-panel__top-plans">
								<div className="left-panel__top-plans-name">Straact { props.t( `${ user?.type == 1 ? 'Organization' : 'Member' }` ) }</div>
								{ user?.type == 1 && (
									<div className="left-panel__top-plans-type">{ `${ plan?.title } plan` }</div>
								)}
							</div>
						</>
					)}
				</div>
				<nav className="left-panel__nav">
					<ul>
						<NavLink to="/" style={{ pointerEvents: 'none' }}>
							<li>
								<RocketLaunchRoundedIcon />
								<span>Dashboard</span>
								{ ! minimized && (
									<label className="coming-soon">{ props.t( 'Coming' ) }</label>
								)}
							</li>
						</NavLink>
						<NavLink to="/projects">
							<li>
								<FolderOpenRoundedIcon />
								<span>{ props.t( 'Projects' ) }</span>
							</li>
						</NavLink>
						<NavLink to="/analytics" style={{ pointerEvents: 'none' }}>
							<li>
								<EqualizerRoundedIcon />
								<span>{ props.t( 'Analytics' ) }</span>
								{ ! minimized && (
									<label className="coming-soon">{ props.t( 'Coming' ) }</label>
								)}
							</li>
						</NavLink>
						<NavLink to="/integrations">
							<li>
								<CompareArrowsRoundedIcon />
								<span>{ props.t( 'Integrations' ) }</span>
								{ ! minimized && (
									<label className="new-feature">{ props.t( 'New!' ) }</label>
								)}
							</li>
						</NavLink>
						{ user?.type == 1 && (
							<NavLink to="/billing">
								<li>
									<PaymentsRoundedIcon />
									<span>{ props.t( 'Billing' ) }</span>
								</li>
							</NavLink>
						)}
						<NavLink className="left-panel__nav-end" to="/help" style={{ pointerEvents: 'none' }}>
							<li>
								<SupportAgentRoundedIcon />
								<span>{ props.t( 'Help' ) }</span>
								{ ! minimized && (
									<label className="coming-soon">{ props.t( 'Coming' ) }</label>
								)}
							</li>
						</NavLink>
						<NavLink to="/settings">
							<li>
								<SettingsRoundedIcon />
								<span>{ props.t( 'Settings' ) }</span>
							</li>
						</NavLink>
					</ul>
				</nav>
				<div className="left-panel__user">
					{ ! minimized && (
						<PrimaryButton
							className="left-panel__user-btn"
							icon={ <AddRoundedIcon/> }
							text={ props.t( 'Add content' ) }
							onClick={ onSubmitEditor }
						/>
					)}

					<div className="left-panel__user-info">
						<div className="left-panel__user-avatar">
							<Avatar />
						</div>
						<div className="left-panel__user-names">
							{ ! user && (
								<Skeleton variant="rectangular" width={ `100%` } height={ 30 } />
							)}
							{ ! minimized && (
								<>
									{ user?.metadata?.company_name }
								</>
							)}
						</div>
						{ ! minimized && (
							<div className="left-panel__user-settings">
								<ToggleSettings { ...props } />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default LeftPanel;
