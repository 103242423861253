import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoodleIllustration from '../assets/dist/img/swinging_doodle.svg';

const SignUpFeatures = () => {
	return (
		<div className="sign-up__features">
			<div className="sign-up__title">Opret jer i dag... gratis.</div>
			<div className="sign-up__features-items">
				<div className="sign-up__features-items__item">
					<div className="sign-up__features-items__item-title">
						<CheckCircleIcon />Skriv indhold 75% hurtigere
					</div>
				</div>
				<div className="sign-up__features-items__item">
					<div className="sign-up__features-items__item-title">
						<CheckCircleIcon />Integrer med dit CMS
					</div>
				</div>
				<div className="sign-up__features-items__item">
					<div className="sign-up__features-items__item-title">
						<CheckCircleIcon />Ingen binding, ingen kreditkort
					</div>
				</div>
				<div className="sign-up__features-items__item">
					<div className="sign-up__features-items__item-title">
						<CheckCircleIcon />Samarbejd med dine kollegaer
					</div>
				</div>
			</div>
			<img className="sign-up__features-illustration" src={ DoodleIllustration } />
		</div>
	)
}

export default SignUpFeatures;