import { STRIPE } from './constants';

const getPlansList = () => {
	return [
		{
			'type': '0',
			'title': 'Free',
			'description': 'For individuals',
			'pricing': {
				'monthly': {
					'DKK': '-',
					'EUR': '-'
				},
				'yearly': {
					'DKK': '-',
					'EUR': '-'
				}
			},
			'features': {
				'users': {
					'label': 'user',
					'value': '1'
				},
				'usage': {
					'label': 'characters (one-time)',
					'value': '10.000'
				},
			}
		},
		{
			'type': '1',
			'title': 'Starter',
			'description': 'For individuals and small businesses',
			'pricing': {
				'monthly': {
					'DKK': 249
				},
				'yearly': {
					'DKK': 4999,
				}
			},
			'features': {
				'users': {
					'label': 'user',
					'value': '1'
				},
				'usage': {
					'label': 'characters monthly',
					'value': '50.000'
				},
			}
		},
		{
			'type': '2',
			'title': 'Pro',
			'description': 'For small businesses',
			'pricing': {
				'monthly': {
					'DKK': 599
				},
				'yearly': {
					'DKK': 4999
				}
			},
			'features': {
				'users': {
					'label': 'users',
					'value': '3'
				},
				'usage': {
					'label': 'characters monthly',
					'value': '150.000'
				},
			}
		},
		{
			'type': '3',
			'title': 'Premium',
			'description': 'For small and medium businesses',
			'pricing': {
				'monthly': {
					'DKK': 1599,
				},
				'yearly': {
					'DKK': 4999,
				}
			},
			'features': {
				'users': {
					'label': 'users',
					'value': '5'
				},
				'usage': {
					'label': 'characters monthly',
					'value': '400.000'
				},
			}
		},
		{
			'type': '4',
			'title': 'Enterprise',
			'description': 'For large businesses',
			'pricing': {
				'monthly': {
					'DKK': 3999,
					'EUR': '-'
				},
				'yearly': {
					'DKK': '-',
					'EUR': '-'
				}
			},
			'features': {
				'users': {
					'label': 'users',
					'value': '10'
				},
				'usage': {
					'label': 'characters',
					'value': 'Unlimited'
				},
				'support': {
					'label': 'support',
					'value': 'Free'
				},
			}
		}
	]
}

const getPaymentLink = ( type ) => {
	let link = '#'

	if ( type === '1' ) {
		link = STRIPE.PAYMENT_LINKS['1_MONTH_STARTER']
	}

	if ( type === '2' ) {
		link = STRIPE.PAYMENT_LINKS['1_MONTH_PRO']
	}

	if ( type === '3' ) {
		link = STRIPE.PAYMENT_LINKS['1_MONTH_PREMIUM']
	}

	if ( type === '4' ) {
		link = STRIPE.PAYMENT_LINKS['1_MONTH_ENTERPRISE']
	}

	return link
}

const planType = ( type ) => {
	let planList = getPlansList()

	return planList.find( plan => plan.type === type )
}

export {
	getPlansList,
	planType,
	getPaymentLink
}
