import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import updateContent from '../../api/content/update-content';
import { getPredefinedTemplates } from '../../utils/templates/predefined-templates';
import { getCustomTemplates } from '../../utils/templates/custom-templates';

const SelectTemplate = () => {
	const dispatch = useDispatch();
	const content  = useSelector( state => state.content );

	const predefinedTemplates = getPredefinedTemplates();
	const customTemplates = getCustomTemplates();

	console.log('customTemplates', customTemplates)

	const handleTemplateClick = ( template ) => {
		let data = { ...content,
			templates: {...content?.templates,
				current_value: template?.value,
			},
			active_tab: 1,
		}

		dispatch({ type: 'GET_CONTENT', payload: data })

		const updateContentThunk = updateContent( data )
		dispatch( updateContentThunk )
	}

	return (
		<Box className="templates-list">
			<Slide direction="right" in={ true } timeout={ 400 }>
				<List
					sx={{ width: '100%', bgcolor: 'background.paper' }}
					component="nav"
					aria-labelledby="nested-list-subheader"
					subheader={
						<ListSubheader component="div" id="nested-list-subheader">
							Predefined templates
						</ListSubheader>
					}
				>
					{ predefinedTemplates && predefinedTemplates.map( template => {
						return (
							<ListItemButton key={ template?.id } onClick={ () => handleTemplateClick( template ) }>
								<ListItemIcon>
									{ template.icon }
								</ListItemIcon>
								<ListItemText primary={ template?.label } secondary={ template?.short_description } />
							</ListItemButton>
						)
					})}
				</List>
			</Slide>
			<Slide direction="right" in={ true } timeout={ 400 }>
				<List
					sx={{ width: '100%', bgcolor: 'background.paper' }}
					component="nav"
					aria-labelledby="nested-list-subheader"
					subheader={
						<ListSubheader component="div" id="nested-list-subheader">
							Custom templates
						</ListSubheader>
					}
				>
					{ customTemplates && customTemplates.map( template => {
						return (
							<ListItemButton key={ template?.id } onClick={ () => handleTemplateClick( template ) }>
								<ListItemIcon>
									{ template.icon }
								</ListItemIcon>
								<ListItemText primary={ template?.label } secondary={ template?.short_description } />
							</ListItemButton>
						)
					})}
				</List>
			</Slide>
		</Box>
	)
}

export default SelectTemplate;