import axios from 'axios';
import { ACCESS_TOKEN, APP_ROOT, API_ENDPOINTS } from '../../utils/constants';

const updateUser = ( user, setRedirect = false ) => {
	return async function updateUserThunk( dispatch ) {
		dispatch({ type: 'SET_IS_LOADING', payload: true })

		axios.post(
			`${API_ENDPOINTS.STRAACT_FLASK_API}/user/update`,
			user,
			{
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		)
		.then( response => {
			if ( response.data.type === 'success' ) {
				dispatch({ type: 'GET_USER', payload: response.data.user })
				dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'Your user was updated' } })

				if ( setRedirect ) {
					setRedirect( true )
				}
			}

			if ( response.data.type === 'error' ) {
				dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})

		dispatch({ type: 'SET_IS_LOADING', payload: false })
	}
}

export default updateUser;
