import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import getUsers from '../../api/backoffice/get-users';
import { planType } from '../../utils/plans';
import BeautyDatetime from '../../utils/BeautyDatetime';

const UsersList = () => {
	const dispatch        = useDispatch();
	const backofficeUsers = useSelector( state => state.backoffice_users );

	useEffect(() => {
		if ( ! backofficeUsers ) {
			const getUsersThunk = getUsers()
			dispatch( getUsersThunk )
		}
	}, []);

	return(
		<>
			<h4>Total users: { backofficeUsers.length }</h4>
			{ backofficeUsers && backofficeUsers.length > 0 && (
				<table>
					<tr>
						<th>UID:</th>
						<th>Contact:</th>
						<th>Plan:</th>
						<th>Usage:</th>
						<th>Type:</th>
						<th>Latest login:</th>
						<th>Created:</th>
					</tr>
				{ backofficeUsers.map( user => {
					return (
						<tr>
							<td>{ user?.uid }</td>
							<td>{ user?.metadata?.company_name }<br/>{ user?.metadata?.contact_person?.name }<br/>{ user?.email }</td>
							<td>{ planType(user?.plan)?.title }</td>
							<td>{ user?.usage?.characters?.total.toLocaleString() }</td>
							<td>{ user?.type === '1' ? 'Organization' : 'Member' }</td>
							<td><BeautyDatetime datetime={ user?.usage?.latest_login } /></td>
							<td><BeautyDatetime datetime={ user?.created } /></td>
						</tr>
					)
				})}
				</table>
			)}
		</>
	)
}

export default UsersList;