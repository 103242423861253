import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Rings } from  'react-loader-spinner';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded';

import createProject from '../../api/project/create-project';
import { PrimaryButton, PrimaryButtonDisabled } from '../buttons/PrimaryButton';
import { SecondaryButton } from '../buttons/SecondaryButton';

const CreateProjectModal = ( props ) => {
	const dispatch = useDispatch();
	const user     = useSelector( state => state.user );
	const loading = useSelector( state => state.loading )
	const [ open, setOpen ]                 = useState( false );
	const [ redirect, setRedirect ]         = useState( false );
	const [ title, setTitle ]               = useState( '' );
	const [ description, setDescription ]   = useState( '' );
	const { id }                            = useParams()
	const navigate                          = useNavigate()

	useEffect(() => {
		if ( redirect !== false ) {
			navigate( redirect )
			setOpen( false )
		}
	}, [ redirect ]);

	const handleOpen = () => {
		setOpen( true );
	}

	const handleClose = () => {
		setOpen( false );
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const onSubmit = ( event ) => {
		event.preventDefault();

		const createProjectThunk = createProject(
			{
				'user': user,
				'title': title,
				'description': description,
				'metadata': {},
				'parent': id ?? '0'
			},
			setRedirect
		)

		dispatch( createProjectThunk )
	}

	let button = '';
	if ( title !== '' && description !== '' ) {
		button = <PrimaryButton text={ props.t( 'Create' ) } type="submit" />
	} else {
		button = <PrimaryButtonDisabled text={ props.t( 'Create' ) } />
	}

	return (
		<>
			<SecondaryButton
				className="create-project__btn"
				icon={ <CreateNewFolderRoundedIcon/> }
				text={ props.t( 'Create project' ) }
				size="small"
				onClick={ handleOpen }
			/>
			<Modal
				className="basic__modal create-project__modal"
				open={open}
				onClose={handleClose}
			>
				<Box
					className="basic__modal-box create-project__modal-box"
					sx={{ ...style, width: '100%', maxWidth: '600px' }}
					disabled={ loading }
				>
					<div className="basic__modal-title create-project__modal-title">{ props.t( 'New' ) } <span>{ props.t( 'project' ) }</span></div>
					<div className="basic__modal-description">{ props.t( 'Projects can contain related content' ) }</div>

					<form
						className="sign-up__form form"
						onSubmit={ onSubmit }
					>
						<div className="form__fields">
							<TextField
								label={ props.t( 'Title' ) }
								defaultValue=""
								variant="outlined"
								autoComplete="new-name"
								onChange={ event => setTitle( event.target.value ) }
							/>
							<TextField
								label={ props.t( 'Give it a short description' ) }
								multiline
								variant="outlined"
								rows={4}
								maxRows={4}
								autoComplete="new-name"
								onChange={ event => setDescription( event.target.value ) }
							/>
							{ button }
						</div>
					</form>
				</Box>
			</Modal>
		</>
	)
}

export default CreateProjectModal;