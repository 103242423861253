const userReducer = ( state = false, action ) => {
	switch( action.type ) {
		case 'CREATE_USER':
			return action.payload
		case 'GET_USER':
			return action.payload
		default:
			return state
	}
}

export default userReducer;