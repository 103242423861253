import axios from 'axios';
import { APP_ROOT, API_ENDPOINTS, ACCESS_TOKEN } from '../../utils/constants';

import getAssociatedProjects from './get-associated-projects';

const deleteProject = ( project_id, parent ) => {
	return async function deleteProjectThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/project/delete`,
			{
				params: {
					project_id: project_id
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_PROJECT', payload: response.data.project })

					const getAssociatedProjectsThunk = getAssociatedProjects( parent )
					dispatch( getAssociatedProjectsThunk )
				}

				if ( response.data.type === 'error' ) {
					dispatch({ type: 'GET_PROJECT', payload: false })
					window.location.href = `${APP_ROOT}`;
				}
			}
		})
	}
}

export default deleteProject;
