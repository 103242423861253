const queryReducer = ( state = false, action ) => {
	switch( action.type ) {
		case 'SET_QUERY_RUNNING' :
			return action.payload
		default:
			return state
	}
}

export default queryReducer;
