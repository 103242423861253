import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const getAssociatedProjects = ( parent_id ) => {
	return async function getAssociatedProjectsThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/project/get`,
			{
				params: {
					uid: UID,
					parent: parent_id
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_ASSOCIATED_PROJECTS', payload: { 'results': response.data.projects, 'breadcrumbs': response.data.breadcrumbs } })
				}
			}
		})
	}
}

export default getAssociatedProjects;
