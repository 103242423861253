import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ShortTextRoundedIcon from '@mui/icons-material/ShortTextRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';

const genericFields = [
	{
		label: 'Tone of voice',
		placeholder: 'Formel',
		id: 'tone',
		tooltip: 'Set the desired tone of the generated text. It may be funny, professional, dizzy etc.',
		type: 'text_input'
	},
	{
		label: 'Language',
		// helper_text: 'The language of the output',
		id: 'lang',
		placeholder: 'Choose a language...',
		type: 'select',
		tooltip: 'The language parameter is the desired output language.',
		items: [
			{
				label: 'Danish',
				value: 'Danish',
			},
			{
				label: 'English',
				value: 'English',
			}
		]
	},
	// {
	// 	label: 'Creativity level',
	// 	// helper_text: 'Set the desired level of creativity',
	// 	tooltip: '',
	// 	id: 'creativity',
	// 	type: 'slider',
	// 	items: [
	// 		{
	// 			label: 'Factual',
	// 			value: 1,
	// 		},
	// 		{
	// 			label: 'Low',
	// 			value: 25,
	// 		},
	// 		{
	// 			label: 'Medium',
	// 			value: 50,
	// 		},
	// 		{
	// 			label: 'High',
	// 			value: 75,
	// 		},
	// 		{
	// 			label: 'Extreme',
	// 			value: 100,
	// 		}
	// 	]
	// },
	// {
	// 	label: 'Output length',
	// 	helper_text: '',
	// 	id: 'length',
	// 	type: 'button_group',
	// 	items: [
	// 		{
	// 			label: 'Small',
	// 			value: 'small',
	// 			icon: <ShortTextRoundedIcon />
	// 		},
	// 		{
	// 			label: 'Medium',
	// 			value: 'medium',
	// 			icon: <NotesRoundedIcon />
	// 		},
	// 		{
	// 			label: 'Long',
	// 			value: 'long',
	// 			icon: <SubjectRoundedIcon />
	// 		}
	// 	]
	// }
]

// davinci:ft-straact:title-gourmetwine-v1-2023-02-26-12-09-21

const getPredefinedTemplates = () => {
	return [
		{
			label: 'Custom command',
			icon: <PlayCircleRoundedIcon className="icon-color-scheme orange-color-scheme" />,
			value: 'custom_command',
			description: 'This template allows you to write your very own custom command to Straact',
			short_description: 'Write your own custom command to Straact',
			fields: [
				{
					label: 'Write the command',
					placeholder: 'Write a paragraph about the flower "Lavender". Include details about the specie.',
					helper_text: 'When giving instructions to Straact, it is important to be specific. You may include certain details that a heading, paragraph, list or something else needs to contain. You can also simply ask a quesion!',
					id: 'command',
					type: 'textarea'
				}
			].concat(...genericFields)
		},
		{
			label: 'Custom model',
			icon: <TuneRoundedIcon className="icon-color-scheme orange-color-scheme" />,
			value: 'custom_model',
			description: 'This template allows you use a custom Straact model',
			short_description: 'Enter the custom model name and fill out your custom parameters',
			fields: [
				{
					label: 'Model name',
					helper_text: 'Enter the name of the custom model',
					id: 'model_name',
					type: 'text_input'
				},
				{
					label: 'Fill out the custom parameters',
					placeholder: '',
					helper_text: 'The parameters needs to be seperated by a line break',
					id: 'parameters',
					type: 'textarea'
				}
			]
		},
		{
			label: 'Rewrite text',
			icon: <AutoFixHighRoundedIcon className="icon-color-scheme orange-color-scheme" />,
			value: 'rewrite',
			description: 'This template allows you to rewrite a piece of text',
			short_description: 'Rewrite a piece of text',
			fields: [
				{
					label: 'The input',
					placeholder: 'Insert your input that needs to be rewritten.',
					// helper_text: 'When giving instructions to Straact, it is important to be specific. You may include certain details that a heading, paragraph, list or something else needs to contain. You can also simply ask a quesion!',
					id: 'input',
					type: 'textarea'
				}
			].concat(...genericFields)
		},
		{
			label: 'Extend',
			icon: <NotesRoundedIcon className="icon-color-scheme orange-color-scheme" />,
			value: 'extend',
			description: 'This template allows you to extend a sentence with more information',
			short_description: 'Extend a sentence',
			fields: [
				{
					label: 'The input',
					placeholder: 'Insert your input that needs to be extended.',
					// helper_text: 'When giving instructions to Straact, it is important to be specific. You may include certain details that a heading, paragraph, list or something else needs to contain. You can also simply ask a quesion!',
					id: 'input',
					type: 'textarea'
				}
			].concat(...genericFields)
		},
		{
			label: 'Product description',
			icon: <ShoppingBagRoundedIcon className="icon-color-scheme green-color-scheme" />,
			value: 'product_description',
			description: 'A compelling description of your product',
			short_description: 'A compelling description of your product',
			fields: [
				{
					label: 'Product title',
					helper_text: 'A product title',
					id: 'product_title',
					type: 'text_input'
				},
				{
					label: 'Tell us about the product',
					placeholder: 'The brand new Tag Heuer Aquaracer watch in a beautiful blue color.',
					helper_text: 'Write a detailed description about your product',
					id: 'product_described',
					type: 'textarea'
				}
			].concat(...genericFields)
		},
		{
			label: 'Blog Post (One-Click)',
			icon: <AssignmentRoundedIcon className="icon-color-scheme blue-color-scheme" />,
			value: 'blog_post_full',
			short_description: 'A fully composed blog post: Heading, intro, body and conclusion',
			description: 'Use our Blog Post (One-Click) template to have a fully composed post ready in minutes. It will include a heading, intro, body and finally a conclusion.',
			fields: [
				{
					label: 'Topic',
					placeholder: 'Cryptocurrency',
					// helper_text: 'Write a topic here',
					id: 'topic',
					type: 'text_input'
				},
				{
					label: 'Short description',
					// helper_text: 'Write a detailed description about the topic and what the post should cover',
					id: 'short_description',
					type: 'textarea'
				},
			].concat(...genericFields)
		},
		{
			label: 'Blog post (outline)',
			icon: <AssignmentRoundedIcon className="icon-color-scheme yellow-color-scheme" />,
			value: 'blog_post_outline',
			description: 'A numbered outline about a topic',
			short_description: 'A numbered outline about a topic',
			fields: [
				{
					label: 'The title/topic of the post',
					helper_text: 'Write a topic here',
					placeholder: 'Football Stadiums',
					id: 'topic',
					type: 'text_input'
				}
			].concat(...genericFields)
		},
		{
			label: 'Ads text',
			icon: <CampaignRoundedIcon className="icon-color-scheme pink-color-scheme" />,
			value: 'ads_text',
			description: 'Generate a highly performant ad text for Facebook or Google.',
			short_description: 'Generate a highly performant ad text for Facebook or Google.',
			fields: [
				{
					label: 'Company/Product title',
					helper_text: 'A full product title',
					id: 'product_title',
					type: 'text_input'
				},
				{
					label: 'Tell us about the product',
					placeholder: 'The brand new Tag Heuer Aquaracer watch in a beautiful blue color.',
					helper_text: 'Write a detailed description about your product',
					id: 'product_described',
					type: 'textarea'
				}
			].concat(...genericFields)
		},
		{
			label: 'Company description',
			icon: <AssignmentRoundedIcon className="icon-color-scheme orange-color-scheme" />,
			value: 'company_description',
			description: 'Generate a kick-ass description about your company',
			short_description: 'Generate a kick-ass description about your company',
			fields: [
				{
					label: 'Company name',
					helper_text: 'What is the name of the company',
					id: 'company_name',
					type: 'text_input'
				},
				{
					label: 'Tells us about the company',
					helper_text: 'Write about the company in detail',
					id: 'company_described',
					type: 'textarea'
				}
			].concat(...genericFields)
		}
	]
}

export {
	getPredefinedTemplates,
}
