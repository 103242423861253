import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import UserRoutes from './routes/Routes';
import DisplayMessage from './components/messages/DisplayMessage';
import DisplayLoading from './components/loading/DisplayLoading';
import i18n from "i18next";
import { initReactI18next, withTranslation } from 'react-i18next';
import da_DK from './lang/da_DK.json';
import 'normalize.css';
import './assets/dist/css/base.css';
import { GetLocalLanguage } from './utils/Language';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { StyledEngineProvider } from '@mui/material/styles';
import reducers from './reducers';

import './components/mui/Mui.css';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const store = createStore(
	reducers,
	composedEnhancer
);

i18n.use( initReactI18next ).init({
	resources: {
		'da_DK': da_DK
	},
	lng: GetLocalLanguage(),
	fallbackLng: 'en_EN',
	interpolation: {
		escapeValue: false
	}
});

const Index = ( props ) => {
	const [ appClasses, setAppClasses ] = React.useState( '' );

	return (
		<>
			<Provider store={ store }>
				<React.StrictMode>
					<StyledEngineProvider injectFirst>
						<BrowserRouter>
							<div className={`app ${ appClasses }`}>
								<UserRoutes
									setAppClasses={ setAppClasses }
									{ ...props }
								/>

								<DisplayLoading />
								<DisplayMessage { ...props } />
							</div>
						</BrowserRouter>
					</StyledEngineProvider>
				</React.StrictMode>
			</Provider>
		</>
	)
}

const IndexApp = withTranslation()(Index);

withTranslation()(ReactDOM.render(
	<IndexApp />,
	document.getElementById('root')
));
