import Cookies from 'js-cookie';
import axios from 'axios';
import { APP_ROOT, API_ENDPOINTS } from '../../utils/constants';

const createUser = ( params, setSecondStep ) => {
	return async function createUserThunk( dispatch ) {
		dispatch({ type: 'SET_IS_LOADING', payload: true })

		axios.post( `${API_ENDPOINTS.STRAACT_FLASK_API}/user/register`, params )
		.then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					if ( response.data.user.authorization.access_token == '' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An error occured accessing the required access_token.' } })
						setSecondStep( false );
					} else {
						Cookies.set( 'straact_authorization', response.data.user.authorization.access_token, { expires: 3 } )
						Cookies.set( 'straact_uid', response.data.user.uid, { expires: 3 } )

						// Linkined Tracking
						if ( window.lintrk ) {
							window.lintrk( 'track', { conversion_id: 9714226 } );
						}

						window.location.href = `${APP_ROOT}/projects`;
					}
				}

				if ( response.data.type === 'error' ) {
					if ( response.data.reason === 'user_exist' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Woops! A user with this email already exists.' } })
						setSecondStep( false );
					}
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})

		dispatch({ type: 'SET_IS_LOADING', payload: false })
	}
}

export default createUser;
