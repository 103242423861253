import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const SettingsMenu = ( props ) => {
	const user = useSelector( state => state.user );

	return (
		<>
			<div className="settings-menu">
				<nav className="settings-menu__nav">
					<ul>
						<NavLink end to="/settings">
							<li>
								<span>{ props.t( 'General' ) }</span>
							</li>
						</NavLink>
						{ user?.type == 1 && (
							<>
								<NavLink to="/settings/plan">
									<li>
										<span>{ props.t( 'Plan & usage' ) }</span>
									</li>
								</NavLink>
								<NavLink to="/settings/members">
									<li>
										<span>{ props.t( 'Organizational members' ) }</span>
									</li>
								</NavLink>
							</>
						)}
						{/* <NavLink to="/settings/language">
							<li>
								<span>{ props.t( 'Language' ) }</span>
							</li>
						</NavLink> */}
					</ul>
				</nav>
			</div>
		</>
	)
}

export default SettingsMenu;
