const GetLocalLanguage = () => {
	let lang = localStorage.getItem( 'lang' );

	if ( lang == null ) {
		lang = SetLocalLanguage()
	}

	return lang;
}

const SetLocalLanguage = ( i18n = false, lang = false ) => {
	if ( lang == false ) {
		lang = DetectLanguage();
	}

	if ( i18n !== false ) {
		i18n.changeLanguage( lang )
	}

	lang = lang.replace( '-', '_' );
	localStorage.setItem( 'lang', lang );

	return lang;
}

const DetectLanguage = () => {
	return navigator.language || navigator.userLanguage;
}

export {
	SetLocalLanguage,
	GetLocalLanguage
}