import { useSelector, useDispatch } from 'react-redux';
import { getPlansList, planType, getPaymentLink } from '../../../utils/plans';
import MemberInviteModal from '../../modals/MemberInviteModal';

import MemberList from './MemberList';

const OrganizationMembers = ( props ) => {
	const user   = useSelector( state => state.user );
	let plan     = planType(user?.plan)

	return (
		<>
			<div className="members">
				<div className="members-overview">
					<div className="members-overview__title">
						{ props.t( 'Members - invite your team' ) }
					</div>
					<div className="members-overview__current">
						<div className="members-overview__current-text">
							<span>{ user?.members?.length ? user?.members?.length + 1 : 1 } { props.t( 'out of' ) } { plan?.features?.users?.value }</span> { props.t( 'seats are taken.' ) }
						</div>
					</div>
					<div className="members-overview__invite">
						<MemberInviteModal currentUsers={ user?.members?.length + 1 } maxUsers={ plan?.features?.users?.value } {...props} />
					</div>
					<div className="members-overview__list">
						<MemberList { ...props } />
					</div>
				</div>
			</div>
		</>
	)
}

export default OrganizationMembers;
