import { useSelector } from 'react-redux';
import { Rings } from  'react-loader-spinner';

const DisplayLoading = () => {
	const loading = useSelector( state => state.loading );

	if ( ! loading ) {
		return '';
	}

	return (
		<div className="page-loading">
			<Rings color="var(--base-button-color)" height={ 100 } width={ 100 } />
		</div>
	)
}

export default DisplayLoading;
