import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { APP_ROOT } from '../utils/constants';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';

import ConnectShopify from '../components/integrations/connect/ConnectShopify';
import ConnectChromeExtension from '../components/integrations/connect/ConnectChromeExtension';
import SetupAPIKey from '../components/integrations/setup/SetupAPIKey';

const Integrations = ( props ) => {
	useEffect(() => {
		props.setAppClasses( 'integrations' )
	}, []);

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Integrations' ),
						'editable': false
					}}
					description={{
						'value': props.t( 'Explore your integrations opportunities' ),
						'editable': false
					}}
				/>

				<div className="settings-sections">
					<div className="settings-sections__item integrations-api-key">
						<SetupAPIKey {...props} />
					</div>
					<div className="settings-sections__item integrations-connect">
						{/* <ConnectShopify {...props} /> */}

						<ConnectChromeExtension {...props} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Integrations;
