import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

import { PrimaryButton, PrimaryButtonDisabled } from '../buttons/PrimaryButton';
import { SecondaryButton, SecondaryButtonDisabled } from '../buttons/SecondaryButton';

import inviteMember from '../../api/member/invite-member';

const MemberInviteModal = ( props ) => {
	const user                                    = useSelector( state => state.user );
	const loading                                 = useSelector( state => state.loading );
	const dispatch                                = useDispatch();
	const [ open, setOpen ]                       = useState( false );
	const [ email, setEmail ] = useState( '' );

	const handleOpen = () => {
		setOpen( true );
	}

	const handleClose = () => {
		setOpen( false );
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const onSubmit = ( event ) => {
		event.preventDefault();

		const inviteMemberThunk = inviteMember(
			{
				'uid': user?.uid,
				'email': email,
			},
			setOpen
		)

		inviteMemberThunk( dispatch )
	}


	let button = '';
	if ( email && email.includes( '@' ) && email.includes( '.' ) ) {
		button = <PrimaryButton text={ props.t( 'Send e-mail invite' ) } type="submit" />
	} else {
		button = <PrimaryButtonDisabled text={ props.t( 'Send e-mail invite' ) } />
	}

	return (
		<>
			{ ! props.currentUsers || ( props.currentUsers == props.maxUsers ) ? (
				<SecondaryButtonDisabled onClick={ handleOpen } size="small" text={ props.t( 'Invite a co-worker' ) } icon={ <PersonAddAltRoundedIcon /> } />
			) : (
				<SecondaryButton onClick={ handleOpen } size="small" text={ props.t( 'Invite a co-worker' ) } icon={ <PersonAddAltRoundedIcon /> } />
			)}
			<Modal
				className="basic__modal editor-rating__modal"
				open={open}
				onClose={handleClose}
			>
				<Box
					className="basic__modal-box editor-rating__modal-box"
					sx={{ ...style, width: '100%', maxWidth: '600px' }}
					disabled={ loading }
				>
					<div className="basic__modal-title editor-rating__modal-title">{ props.t( 'Invite members' ) }</div>
					<div className="basic__modal-description">{ props.t( 'Organizations can invite members to participate' ) }</div>

					<form
						className="sign-up__form form"
						onSubmit={ onSubmit }
					>
						<div className="form__fields">
							<TextField
								label={ props.t( 'Enter e-mail' ) }
								multiline
								variant="outlined"
								autoComplete="new-name"
								onChange={ event => setEmail( event.target.value ) }
							/>
							{ button }
						</div>
					</form>
				</Box>
			</Modal>
		</>
	)
}

export default MemberInviteModal;
