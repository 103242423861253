import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FilterDramaRoundedIcon from '@mui/icons-material/FilterDramaRounded';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';
import SettingsMenu from '../components/settings/SettingsMenu';
import { SecondaryButton, SecondaryButtonDisabled } from '../components/buttons/SecondaryButton';
import updateUser from '../api/user/update-user';
import { SetLocalLanguage } from '../utils/Language';

const Settings = ( props ) => {
	const user = useSelector( state => state.user );
	const dispatch = useDispatch();

	useEffect(() => {
		props.setAppClasses( 'settings' )
	}, []);

	// Initial settings values
	const [settings, setSettings] = useState({
		companyName: user?.metadata?.company_name ?? '',
		vat: user?.metadata?.company_vat ?? '',
		address: user?.metadata?.address ?? '',
		contactPerson: user?.metadata?.contact_person?.name ?? '',
		websiteUrl: user?.metadata?.website?.url ?? '',
	});

	// If user is changed, e.g. on page refresh.
	useEffect(() => {
		setSettings({
			companyName: user?.metadata?.company_name ?? '',
			vat: user?.metadata?.company_vat ?? '',
			address: user?.metadata?.address ?? '',
			email: user?.email,
			contactPerson: user?.metadata?.contact_person?.name ?? '',
			websiteUrl: user?.metadata?.website?.url ?? '',
		})
	}, [user]);

	const handleChange = (event) => {
		setSettings({ ...settings, [event.target.name]: event.target.value });
	};

	const onSubmit = ( event ) => {
		event.preventDefault();

		let metadata = { ...user.metadata,
			company_name: settings?.companyName,
			company_vat: settings?.vat,
			address: settings?.address,
			contact_person: {
				name: settings?.contactPerson
			},
			website: {
				url: settings?.websiteUrl
			}
		}

		let data = { ...user,
			email: settings?.email,
			metadata: metadata
		}

		const updateUserThunk = updateUser( data )
		dispatch(updateUserThunk)
	}

	let button = '';
	if ( user?.metadata?.contact_person?.name !== '' && user?.metadata?.company_name !== '' ) {
		button = <SecondaryButton icon={ <FilterDramaRoundedIcon />} size="small" text={ props.t( "Update info" ) } type="submit" />
	} else {
		button = <SecondaryButtonDisabled icon={ <FilterDramaRoundedIcon />} size="small" text={ props.t( "Update info" ) } />
	}

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Settings' ),
						'editable': false
					}}
					description={{
						'value': props.t( "Change the company settings" ),
						'editable': false,
					}}
				/>

				<SettingsMenu {...props} />

				<div className="settings-sections">
					<div className="settings-sections__item">
						<form className="settings-form form" onSubmit={ onSubmit }>
							<div className="form__fields">
								{ user?.type == 1 && (
									<>
										<TextField
											name="companyName"
											label={ props.t( "Company name" ) }
											value={ settings?.companyName }
											variant="outlined"
											autoComplete="new-name"
											required
											onChange={ ( event ) => handleChange( event ) }
										/>
										<TextField
											name="vat"
											label={ props.t( "VAT no." ) }
											value={ settings?.vat }
											variant="outlined"
											autoComplete="new-name"
											onChange={ ( event ) => handleChange( event ) }
										/>
										<TextField
											name="contactPerson"
											label={ props.t( "Contact person" ) }
											value={ settings?.contactPerson }
											variant="outlined"
											autoComplete="new-name"
											className="form__fields-one-column"
											required
											onChange={ ( event ) => handleChange( event ) }
										/>
									</>
								)}
								{ settings?.email && (
									<TextField
										name="email"
										label={ props.t( "Email" ) }
										value={ settings?.email }
										variant="outlined"
										autoComplete="new-name"
										className="form__fields-one-column"
										required
										onChange={ ( event ) => handleChange( event ) }
									/>
								)}
								{ user?.type == 1 && (
									<>
										<TextField
											label={ props.t( 'Company address' ) }
											value={ settings?.address }
											variant="outlined"
											autoComplete="new-name"
											className="form__fields-one-column"
											name="address"
											onChange={ ( event ) => handleChange( event ) }
										/>
										<TextField
											label={ props.t( 'Website' ) }
											value={ settings?.websiteUrl }
											variant="outlined"
											autoComplete="new-name"
											className="form__fields-one-column"
											InputProps={{
												startAdornment: <InputAdornment position="start">https://</InputAdornment>,
											}}
											name="websiteUrl"
											required
											onChange={ ( event ) => handleChange( event ) }
										/>
									</>
								)}
								{ button }
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default Settings;
