import { useSelector, useDispatch } from 'react-redux';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { SecondaryButtonDisabled } from '../buttons/SecondaryButton';
import { STRIPE } from '../../utils/constants';
import { getPlansList, planType, getPaymentLink } from '../../utils/plans';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import StoreIcon from '@mui/icons-material/Store';

const PricingPlans = ( props ) => {
	const dispatch = useDispatch();
	const user     = useSelector( state => state.user );

	const handleCheckoutClick = ( type ) => {
		window.location.href = getPaymentLink( type )
	}

	let planList = getPlansList()

	return (
		<div className="pricing-plans">
			{ planList && planList.length > 0 && planList.map( plan => {
				if ( plan?.type === '0' ) {
					return ''
				}

				return (
					<div className={ `pricing-plans__plan${ plan?.type === user?.plan ? ' active' : '' }` } key={ plan?.type }>
						<div className="pricing-plans__plan-head">
							<div className="pricing-plans__plan-title">
								{ props.t( plan?.title ) }
							</div>
							<div className="pricing-plans__plan-description">
								{ props.t( plan?.description ) }
							</div>
							<div className="pricing-plans__plan-price">
								<span className="pricing-plans__plan-price-amount">{ plan?.type === '4' ? `${ props.t( 'Min.' ) } ` : ''}{ plan?.pricing?.monthly?.DKK.toLocaleString() }</span>
								<span className="pricing-plans__plan-price-currency">DKK</span>
								<span className="pricing-plans__plan-price-recurring">{ props.t( 'per month' ) }</span>
							</div>
							{ user && (
								<div className="pricing-plans__plan-upgrade">
									{  plan?.type === user?.plan && (
										<SecondaryButtonDisabled size="small" text={ props.t( 'Current plan' ) } />
									)}

									{ plan?.type > user?.plan && (
										<PrimaryButton size="small" icon={ <StoreIcon /> } text={ props.t( `Upgrade to ${plan?.title }` ) } onClick={ () => handleCheckoutClick( plan?.type ) } />
									) }

									{ plan?.type < user?.plan && (
										<SecondaryButtonDisabled size="small" text={ props.t( `Downgrade to ${plan?.title }` ) } onClick={ () => handleCheckoutClick( plan?.type ) } />
									) }
								</div>
							)}
						</div>
						<div className="pricing-plans__plan-content">
							<div className="pricing-plans__plan-features">
								<div className="pricing-plans__plan-features-title">
									{ props.t( 'Includes' ) }
								</div>
								{ plan?.features && Object.keys( plan?.features ).map( index => {
									return (
										<div className="pricing-plans__plan-feature" key={ index }>
											<div className="pricing-plans__plan-feature-icon"><CheckCircleIcon /></div>
											<div className="pricing-plans__plan-feature-info">
												<span className="pricing-plans__plan-feature-value">{ props.t( plan?.features[index]?.value ) } </span>
												<span className="pricing-plans__plan-feature-label">{ props.t( plan?.features[index]?.label ) }</span>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default PricingPlans;
