import Button from '@mui/material/Button';

const PrimaryButton = ( props ) => {
	return (
		<>
			<Button
				startIcon={ props?.icon }
				variant="contained"
				className={ `btn primary-btn ${props?.className}` }
				type={ props?.type }
				onClick={ props?.onClick }
				size={ props?.size ?? 'medium' }
				style={ props?.style }
			>
				{ props.text }
			</Button>
		</>
	)
}

export default PrimaryButton;