import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';

const ContentType = ( type ) => {
	let contentType = {}

	switch ( type ) {
		case 1:
			contentType = {
				'title': 'Product',
				'description': 'SEO optimized product information',
				'icon': <ShoppingBagRoundedIcon />
			}

			return contentType;

		case 2:
			contentType = {
				'title': 'Blog',
				'description': 'Catchy blog post titles and meaningful content',
				'icon': <AssignmentRoundedIcon />
			}

			return contentType;

		case 3:
			contentType = {
				'title': 'One-liners',
				'description': 'One-liners for better convertion',
				'icon': <CampaignRoundedIcon />
			}

			return contentType

		default :
			return contentType
	}
}

export default ContentType;
