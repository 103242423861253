import { UID, ACCESS_TOKEN } from './constants';

const AuthUser = () => {
	if ( UID === undefined && ACCESS_TOKEN === undefined ) {
		return false;
	}

	return true;
}

export default AuthUser;
