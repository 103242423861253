import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PrimaryButton } from '../../buttons/PrimaryButton';
import { SecondaryButton } from '../../buttons/SecondaryButton';

import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import generateAPIKey from '../../../api/user/generate-api-key';
import refreshAPIKey from '../../../api/user/refresh-api-key';
import removeAPIKey from '../../../api/user/remove-api-key';

const SetupAPIKey = ( props ) => {
	const dispatch                          = useDispatch();
	const user                              = useSelector( state => state.user );

	if ( ! user ) {
		return ''
	}

	const handleRefreshAPIKey = () => {
		const refreshAPIKeyThunk = refreshAPIKey()
		if ( refreshAPIKeyThunk ) {
			dispatch(refreshAPIKeyThunk)
		}
	}

	const handleGenerateAPIKey = () => {
		const generateAPIKeyThunk = generateAPIKey()
		if ( generateAPIKeyThunk ) {
			dispatch(generateAPIKeyThunk)
		}
	}

	const handleRemoveAPIKey = () => {
		const removeAPIKeyThunk = removeAPIKey()
		if ( removeAPIKeyThunk ) {
			dispatch(removeAPIKeyThunk)
		}
	}

	return (
		<>
			<div className="settings-sections__item-title">
				{ props.t( 'Your API-key' ) }
			</div>
			{ user && user?.integrations?.api_key?.value ? (
				<div className="manage-api-key">
					<div className="manage-api-key__value">{ user?.integrations?.api_key?.value }</div>

					<div className="manage-api-key__controls">
						<PrimaryButton icon={ <RefreshRoundedIcon /> } text={ props.t( 'Refresh API-key' ) } size="small" onClick={ handleRefreshAPIKey } />
						<SecondaryButton icon={ <RemoveCircleIcon /> } text={ props.t( 'Remove' ) } size="small" onClick={ handleRemoveAPIKey } />
					</div>
				</div>
			) : (
				<PrimaryButton icon={ <VpnKeyRoundedIcon /> } text={ props.t( 'Generate API-key' ) } size="small" onClick={ handleGenerateAPIKey } />
			)}
		</>
	)
}

export default SetupAPIKey;
