import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const getUsers = () => {
	if ( UID === undefined && ACCESS_TOKEN === undefined ) {
		return false;
	}

	return async function getUserThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/backoffice/users/get`,
			{
				params: {
					uid: UID
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'BACKOFFICE_GET_USERS', payload: response.data.users })
				}
			}
		})
	}
}

export default getUsers;
