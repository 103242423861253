/**
* Validate the typed password and see if it complies with our terms
*
*
* @return setStrength | setPassord
*/
const PasswordController = ( value, setStrength, setPassword ) => {
	let capital = false;
	let number  = false;
	let len     = false;

	if ( /[A-Z]/.test( value ) ) {
		capital = true;
	}

	if ( /[0-9]/.test( value ) ) {
		number = true;
	}

	if ( value.length > 7 ) {
		len = true;
	}

	if ( value.length == 0 ) {
		setStrength( '' );
	} else {
		setStrength( 'Very weak' );
	}

	if ( ( capital || number || len ) == true ) {
		setStrength( 'Weak' );
	}

	if ( ( ( capital && number ) == true || ( capital && len ) == true ) || ( len && number ) == true ) {
		setStrength( 'Medium' );
	}

	if ( ( capital && number && len ) == true ) {
		setStrength( 'Strong' );
		setPassword( value );
	}
}


/**
* Indicate the password strength
*
*
* @return strength
*/
const StrengthIndicator = ( strength ) => {
	if ( strength === '' ) {
		return;
	}

	return (
		<>
			<div className={'password-strength-indicator ' + strength.replace( ' ', '-' ).toLowerCase() }>
				<div className="indi">
					<b>Styrke:</b><span className="strength-type">{ strength }</span>
				</div>
				<div className="bar"></div>
			</div>
		</>
	)
}

export {
	PasswordController,
	StrengthIndicator
}