import Button from '@mui/material/Button';

const ThirdButton = ( props ) => {
	return (
		<>
			<Button
				startIcon={ props.icon }
				variant="contained"
				className={ `btn third-btn ${props.className}` }
				type={ props.type }
				onClick={ props.onClick }
				size={ props.size ?? 'medium' }
			>
				{ props.text }
			</Button>
		</>
	)
}

export default ThirdButton;