const stripeReducer = ( state = false, action ) => {
	switch( action.type ) {
		case 'GET_INVOICES':
			return action.payload
		default:
			return state
	}
}

export default stripeReducer;
