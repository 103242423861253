import Button from '@mui/material/Button';

const SecondaryButtonDisabled = ( props ) => {
	return (
		<>
			<Button
				startIcon={ props.icon }
				variant="contained"
				disabled
				size={ props.size ?? 'medium' }
				className={ `btn secondary-btn ${props.className}` }
			>
				{ props.text }
			</Button>
		</>
	)
}

export default SecondaryButtonDisabled;