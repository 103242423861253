import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Undo from 'editorjs-undo';
import DragDrop from 'editorjs-drag-drop';
import MarkerTool from './toolbar/MarkerTool';
import RunCommandTool from './toolbar/RunCommandTool';
import RewriteCommandTool from './toolbar/RewriteCommandTool';
import ExtendCommandTool from './toolbar/ExtendCommandTool';

import updateContent from '../../api/content/update-content';
import { FIREBASE_APP, FIRESTORE_DB } from '../../utils/firestore';
import { onSnapshot, query, collection, where, collectionGroup } from "firebase/firestore";

const ContentEditor = ( props ) => {
	const dispatch                            = useDispatch();
	const loading                             = useSelector( state => state.loading );
	const query_running                       = useSelector( state => state.query_running );
	const content                             = useSelector( state => state.content );
	const user                                = useSelector( state => state.user );
	const [snapshotQuery, setSnapshotQuery]   = useState( {} )
	const [editorInstance, setEditorInstance] = useState( false )

	/* Listening on the snapshotQuery state and whenever it changes it will insert a new block to the editor. */
	useEffect(() => {
		if ( ! editorInstance ) {
			return;
		}

		if ( Array.isArray( snapshotQuery?.body ) ) {
			let latestQuery = snapshotQuery['body'][snapshotQuery['body'].length - 1]

			let no_of_blocks = editorInstance.blocks.getBlocksCount();
			let text = `<b>${latestQuery?.label}:</b><br>`
			if ( Array.isArray( latestQuery?.items ) ) {
				latestQuery?.items.map( item => {
					text += item.text
				})
			}

			editorInstance.blocks.insert(
				latestQuery?.editor_block?.type ?? 'paragraph', // Type
				{ ...latestQuery?.editor_block?.data, text: text }, // Text
				{}, // Empty Tool Config
				no_of_blocks, // Index
				true // Focus
			)
		}
	}, [snapshotQuery, editorInstance])

	/* Listening on the sub collection "queries" for changes. */
	useEffect(() => {
		if ( ! query_running ) {
			return;
		}

		let firstSnapshot = true;

		// Find all queries in the sub collection "queries" that matches content_id
		const q = query(collectionGroup(FIRESTORE_DB, "queries"), where("owner", "==", user?.uid), where("content_id", "==", content?.id));
		const unsubscribe = onSnapshot(q, (snapshot) => {
			// Return first snapshot as that contains every document available. We only need the changes.
			if ( firstSnapshot ) {
				firstSnapshot = false;
				return;
			}

			snapshot.docChanges().forEach((change) => {
				// Whenever a new query is added to the sub collection
				if (change.type === "added") {
					let data = change.doc.data()

					if ( snapshotQuery !== data ) {
						setSnapshotQuery( data )
					}
				}

				// Whenever the recently added in the sub collection is modified / changed
				if (change.type === "modified") {
					let data = change.doc.data()

					if ( snapshotQuery !== data ) {
						setSnapshotQuery( data )
					}
				}
			});
		});

		return () => {
			unsubscribe()
		}
	}, [query_running])


	/* Creating a new EditorJS instance and setting the editorInstance state to the editor. */
	useEffect( () => {
		if ( ! content ) {
			return;
		}

		if ( editorInstance !== false ) {
			return;
		}

		let editorParams = {
			// https://github.com/codex-team/editor.js/blob/next/types/configs/editor-config.d.ts
			/**
			 * Id of Element that should contain the Editor
			 */
			holder: 'editorjs',
			placeholder: props.t( `Write your first command to Straact and run it by CMD+SHIFT+R` ),
			maxWidth: '100%',
			minHeight: 0,

			/**
			 * Available Tools list.
			 * Pass Tool's class or Settings object for each Tool you want to use
			 */
			tools: {
				// marker: {
				// 	class: MarkerTool,
				// 	inlineToolbar: true
				// },
				// writeCommand: {
				// 	class: RunCommandTool,
				// 	config: {
				// 		params: {
				// 			'id': content.id,
				// 			'query': {
				// 				template: "custom_command"
				// 			},
				// 			'user': user
				// 		},
				// 		dispatch: dispatch
				// 	},
				// 	shortcut: "CMD+SHIFT+C",
				// },
				// rewriteCommand: {
				// 	class: RewriteCommandTool,
				// 	config: {
				// 		params: {
				// 			'id': content.id,
				// 			'query': {
				// 				template: "rewrite"
				// 			},
				// 			'user': user
				// 		},
				// 		dispatch: dispatch
				// 	},
				// 	shortcut: "CMD+SHIFT+T",
				// },
				// extendCommand: {
				// 	class: ExtendCommandTool,
				// 	config: {
				// 		params: {
				// 			'id': content.id,
				// 			'query': {
				// 				template: "extend"
				// 			},
				// 			'user': user
				// 		},
				// 		dispatch: dispatch
				// 	},
				// 	shortcut: "CMD+SHIFT+E",
				// },
				header: {
					class: Header,
					inlineToolbar: true
				},
				paragraph: {
					class: Paragraph,
					inlineToolbar: true,
					config: {
						placeholder: 'Write your first command to Straact and run it by CMD+SHIFT+R'
					},
					preserveBlank: true,
				},
				list: {
					class: List,
					inlineToolbar: true
				}
			},
			/**
			* onReady callback
			*/
			onReady: () => {
				new Undo({ editor });
				new DragDrop( editor );
				setEditorInstance( editor )
			},
			onChange: () => {
				saveContent( editor )
			},
		}

		if ( content?.editor ) {
			let parsedEditor = JSON.parse( content?.editor )

			if ( parsedEditor ) {
				editorParams.data = parsedEditor;
			}
		}

		const editor = new EditorJS( editorParams )

	}, [ content, editorInstance ] )

	/* A debounced function that saves the content to the database. */
	const saveContent = useMemo( () => debounce( ( editorInstance ) => {
		if ( ! loading ) {
			editorInstance.save().then( ( outputData ) => {
				let data = { ...content,
					editor: JSON.stringify( outputData )
				}

				const updateContentThunk = updateContent( data )
				dispatch( updateContentThunk )
			}).catch((error) => {
				console.log('Saving failed: ', error)
			});
		}
	}, 1000 ), []);

	return (
		<div id="editorjs"></div>
	)
}

export default ContentEditor;