import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ShortTextRoundedIcon from '@mui/icons-material/ShortTextRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';

const genericFields = [
	{
		label: 'Tone of voice',
		placeholder: 'Formel',
		id: 'tone',
		tooltip: 'Set the desired tone of the generated text. It may be funny, professional, dizzy etc.',
		type: 'text_input'
	},
	{
		label: 'Language',
		// helper_text: 'The language of the output',
		id: 'lang',
		placeholder: 'Choose a language...',
		type: 'select',
		tooltip: 'The language parameter is the desired output language.',
		items: [
			{
				label: 'Danish',
				value: 'Danish',
			},
			{
				label: 'English',
				value: 'English',
			}
		]
	},
	// {
	// 	label: 'Creativity level',
	// 	// helper_text: 'Set the desired level of creativity',
	// 	tooltip: '',
	// 	id: 'creativity',
	// 	type: 'slider',
	// 	items: [
	// 		{
	// 			label: 'Factual',
	// 			value: 1,
	// 		},
	// 		{
	// 			label: 'Low',
	// 			value: 25,
	// 		},
	// 		{
	// 			label: 'Medium',
	// 			value: 50,
	// 		},
	// 		{
	// 			label: 'High',
	// 			value: 75,
	// 		},
	// 		{
	// 			label: 'Extreme',
	// 			value: 100,
	// 		}
	// 	]
	// },
	// {
	// 	label: 'Output length',
	// 	helper_text: '',
	// 	id: 'length',
	// 	type: 'button_group',
	// 	items: [
	// 		{
	// 			label: 'Small',
	// 			value: 'small',
	// 			icon: <ShortTextRoundedIcon />
	// 		},
	// 		{
	// 			label: 'Medium',
	// 			value: 'medium',
	// 			icon: <NotesRoundedIcon />
	// 		},
	// 		{
	// 			label: 'Long',
	// 			value: 'long',
	// 			icon: <SubjectRoundedIcon />
	// 		}
	// 	]
	// }
]

// davinci:ft-straact:title-gourmetwine-v1-2023-02-26-12-09-21

const getCustomTemplates = () => {
	return [
		{
			label: 'Product description (Custom)',
			icon: <TuneRoundedIcon className="icon-color-scheme orange-color-scheme" />,
			value: 'custom_product_description',
			description: 'This template allows you use a custom Product Description model',
			short_description: 'Enter an example of a product description and fill out your custom parameters to help the model provide better results',
			fields: [
				{
					label: 'Example',
					helper_text: 'Give an example of the desired outcome',
					id: 'example',
					type: 'textarea'
				},
				{
					label: 'Fill out the custom parameters',
					placeholder: '',
					helper_text: 'The parameters needs to be seperated by a line break',
					value: "Farve:\nKategori:\nMærke:\nMateriale:\nAntal personer:\nPris:\nHøjre-og venstrevendt:\nStørrelse (længde, bredde, dybde):\nForm:\nBetræk:\nSiddehøjde:\nFunktion:\nAndre tillægsord:",
					id: 'parameters',
					type: 'textarea'
				}
			]
		},
	]
}

export {
	getCustomTemplates,
}
