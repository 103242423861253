import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Rings } from 'react-loader-spinner';

import LeftPanelEditor from '../layouts/LeftPanelEditor';
import TopPanel from '../layouts/TopPanel';
import getContent from '../api/content/get-content';

import ContentEditor from '../components/editor/ContentEditor';

const Editor = ( props ) => {
	const dispatch                      = useDispatch();
	const content                       = useSelector(state => state.content);
	const { id }                        = useParams()
	const location                      = useLocation()
	const [ category, setCategory ]     = useState( 1 );
	const [ lang, setLang ]             = useState( 'Danish' );
	const [ variant, setVariant ]       = useState( 1 );
	const [ creativity, setCreativity ] = useState( 1 );
	const [ keywords, setKeywords ]     = useState( '' );
	const [ maxOutput, setMaxOutput ]   = useState( 3900 );

	useEffect(() => {
		props.setAppClasses('editor')
	}, []);

	// Runs if ID param changes e.g. page refresh
	useEffect(() => {
		if (id) {
			const getContentThunk = getContent(id)
			dispatch(getContentThunk)
		}
	}, [id]);

	// Runs if location changes e.g. clicking around the documents
	useEffect(() => {
		if ( location?.state?.content ) {
			dispatch({ type: 'GET_CONTENT', payload: location?.state?.content })
		}
	}, [ location?.state?.content ]);

	if ( ! content ) {
		return (
			<>
				<LeftPanelEditor { ...props } />

				<div className="page-loading">
					<Rings color="var(--base-button-color)" height={100} width={100} />
				</div>
			</>
		)
	}

	return (
		<>
			<LeftPanelEditor
				{ ...props }
			/>

			<div className="right-panel">
				<div className="right-panel__content-view">
					<div className="right-panel__content-view-inner">
						{/* <TopPanel
							title={{
								'value': content?.title,
								'editable': true,
								'changed': content?.id
							}}
							isContent
							{ ...props }
						/> */}

						<ContentEditor {...props} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Editor;
