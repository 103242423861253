import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import ElectricBoltRoundedIcon from '@mui/icons-material/ElectricBoltRounded';

import { PrimaryButton, PrimaryButtonDisabled } from '../../components/buttons/PrimaryButton';

import writeContent from '../../api/content/write-content';
import updateContent from '../../api/content/update-content';
import { getTemplate, getField } from '../../utils/templates/get-template';

const TemplateForm = () => {
	const dispatch = useDispatch();
	const containerRef = useRef(null);
	const user     = useSelector( state => state.user );
	const content  = useSelector( state => state.content );

	const template = getTemplate( content?.templates?.current_value );

	console.log('template', template)

	const handleTemplateEditorFormSubmit = ( event ) => {
		event.preventDefault();

		console.log('content?.templates[template?.value]', content?.templates[template?.value])

		let template_field_values      = content?.templates[template?.value] ?? {};
		template_field_values.template = template?.value

		let params = {
			'id': content.id,
			'args': template_field_values,
			'user': user
		}

		const writeContentThunk = writeContent(
			params
		)

		dispatch( writeContentThunk )
	}

	return (
		<Box ref={containerRef}>
			<Slide direction="left" in={ true } container={containerRef.current} timeout={ 400 }>
				<Box className="template-form">
					<div className="template-form__info">
						<h3 className="template-form__info-title">{ template?.icon } { template?.label }</h3>
						<div className="template-form__info-text">
							{ template?.description }
						</div>
					</div>
					<form onSubmit={ handleTemplateEditorFormSubmit }>
						<Box
							className="form__fields"
							style={{ gridGap: 'var(--spacing-3)' }}
						>
							{ template?.fields && template?.fields.map( field => {
								return (
									getField( dispatch, content, template, field )
								)
							})}
						</Box>

						<Box className="template-form__static-submit">
							<PrimaryButton className="btn" text={ 'Start writing' } icon={ <ElectricBoltRoundedIcon />} type="submit" />
						</Box>
					</form>
				</Box>
			</Slide>
		</Box>
	)
}

export default TemplateForm;