import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import LeftPanel from '../../layouts/LeftPanel';
import TopPanel from '../../layouts/TopPanel';
import SettingsMenu from '../../components/settings/SettingsMenu';
import { SecondaryButton, SecondaryButtonDisabled } from '../../components/buttons/SecondaryButton';
import updateUser from '../../api/user/update-user';
import { SetLocalLanguage } from '../../utils/Language';

const LanguageSettings = ( props ) => {
	const user = useSelector( state => state.user );
	const dispatch = useDispatch();

	useEffect(() => {
		props.setAppClasses( 'settings' )
	}, []);

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Settings' ),
						'editable': false
					}}
					description={{
						'value': props.t( "Change the company settings" ),
						'editable': false,
					}}
				/>

				<SettingsMenu {...props} />

				<div className="settings-sections">
					<div className="settings-sections__item">
						<form className="settings-form form">
							<div className="form__fields">
								<Select
									value={ user?.metadata?.lang ?? '' }
									label="Language"
									onChange={ event => {
										let metadata = { ...user.metadata, lang: event.target.value }
										SetLocalLanguage( props.i18n, event.target.value );
										const updateUserThunk = updateUser( { ...user, metadata: metadata } )
										dispatch(updateUserThunk)
									} }
								>
									<MenuItem value={ 'da_DK' }>Dansk</MenuItem>
									<MenuItem value={ 'en_EN' }>English</MenuItem>
								</Select>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default LanguageSettings;
