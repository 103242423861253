import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const getMembers = () => {
	return async function getMembersThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/member/get`,
			{
				params: {
					uid: UID,
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_MEMBERS', payload: response.data.members } )
				}
			}
		})
	}
}

export default getMembers;
