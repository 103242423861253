import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../../utils/constants';

const updateProject = ( project ) => {
	return async function updateProjectThunk( dispatch ) {
		axios.post(
			`${API_ENDPOINTS.STRAACT_FLASK_API}/project/update`,
			project,
			{
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data.type === 'success' ) {
				dispatch({ type: 'GET_PROJECT', payload: response.data.project })
			}

			if ( response.data.type === 'error' ) {
				dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})
	}
}

export default updateProject;
