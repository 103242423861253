import Cookies from 'js-cookie';
import axios from 'axios';
import { APP_ROOT, API_ENDPOINTS } from '../../utils/constants';

const loginUser = ( params ) => {
	return async function loginUserThunk( dispatch ) {
		dispatch({ type: 'SET_IS_LOADING', payload: true })

		axios.post( `${API_ENDPOINTS.STRAACT_FLASK_API}/user/login`, params )
		.then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					if ( response.data.user.authorization.access_token == '' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An error occured accessing the required access_token.' } })
					} else {
						Cookies.set( 'straact_authorization', response.data.user.authorization.access_token, { expires: 7 } )
						Cookies.set( 'straact_uid', response.data.user.uid, { expires: 7 } )
						window.location.href = `${APP_ROOT}`;
					}
				}

				if ( response.data.type === 'error' ) {
					if ( response.data.reason === 'no_user_found' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'We did not find any user with this e-mail.' } })
					}

					if ( response.data.reason === 'no_google_match' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'We did not find any user with this e-mail.' } })
					}

					if ( response.data.reason === 'standard_created_user' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'This user was not created using Google. Please login with your email and password instead.' } })
					}

					if ( response.data.reason === 'google_created_user' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'This user was created using Google. Please login with Google instead.' } })
					}

					if ( response.data.reason === 'wrong_password' ) {
						dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Woops! Incorrent password' } })
					}

					if ( response.data.reason === 'user_missing_details' ) {
						Cookies.set( 'straact_authorization', response.data.user.authorization.access_token, { expires: 7 } )
						Cookies.set( 'straact_uid', response.data.user.uid, { expires: 7 } )
						window.location.href = `${APP_ROOT}/login/details`;
					}
				}
			}

			dispatch({ type: 'SET_IS_LOADING', payload: false })
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
			dispatch({ type: 'SET_IS_LOADING', payload: false })
		})
	}
}

export default loginUser;
