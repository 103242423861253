import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SelectTemplate from '../components/templates/SelectTemplate';
import TemplateForm from '../components/templates/TemplateForm';

const LeftPanelEditor = ( props ) => {
	const dispatch = useDispatch();
	const content  = useSelector( state => state.content );

	const handleActiveTabChange = (event, newValue) => {
		let data = { ...content,
			active_tab: newValue,
		}

		dispatch({ type: 'GET_CONTENT', payload: data })
	}

	if ( content?.active_tab === undefined ) {
		return '';
	}

	return (
		<>
			<div className={`left-panel`}>
				<Box>
					<Tabs value={ content?.active_tab } onChange={ handleActiveTabChange } aria-label="basic tabs example" variant="fullWidth" centered>
						<Tab label={ props.t( 'Templates' ) } />
						<Tab label={ props.t( 'Write' ) } />
					</Tabs>
				</Box>


				{ content?.active_tab == 0 && (
					<SelectTemplate />
				)}

				{ content?.active_tab == 1 && (
					<TemplateForm />
				)}

			</div>
		</>
	)
}

export default LeftPanelEditor;
