import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const productionConfig = {
	apiKey: "AIzaSyDE3zXUIHS4R2f1gRy6gw54rlYVE8ROSyA",
	authDomain: "bold-apricot-165517.firebaseapp.com",
	projectId: "bold-apricot-165517",
	storageBucket: "bold-apricot-165517.appspot.com",
	messagingSenderId: "353929273303",
	appId: "1:353929273303:web:8ed5ad4d661130178bf592"
};

const stageConfig = {
	apiKey: "AIzaSyCyq9v5faGkk14lnJvTnBQD7RwWzzTNR9E",
	authDomain: "straact-stage-a027b.firebaseapp.com",
	projectId: "straact-stage-a027b",
	storageBucket: "straact-stage-a027b.appspot.com",
	messagingSenderId: "249354861935",
	appId: "1:249354861935:web:823c8d57058c2178383c9a"
};

const localConfig = {
	apiKey: "AIzaSyCyq9v5faGkk14lnJvTnBQD7RwWzzTNR9E",
	authDomain: "straact-stage-a027b.firebaseapp.com",
	projectId: "straact-stage-a027b",
	storageBucket: "straact-stage-a027b.appspot.com",
	messagingSenderId: "249354861935",
	appId: "1:249354861935:web:823c8d57058c2178383c9a"
};

const getConfig = () => {
	switch( window.location.hostname ) {
		case 'localhost':
			return localConfig
		case 'straact-app-staging.web.app':
			return stageConfig

		default:
			return productionConfig
	}
}


// Initialize Firebase
export const FIREBASE_APP  = initializeApp(getConfig());
export const FIREBASE_AUTH = getAuth( FIREBASE_APP );
export const FIRESTORE_DB  = getFirestore( FIREBASE_APP );