import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProjectBreadcrumbs = ( props ) => {
	const crumbs = useSelector( state => state.associated_projects?.breadcrumbs );

	return (
		<>
			<div className="breadcrumbs project-breadcrumbs">
				{ crumbs && crumbs.length > 0 && crumbs.map( crumb => {
					return (
						<>
							<div className="breadcrumbs__item project-breadcrumbs__item" key={ crumb.id }>
								<Link
									key={ crumb.id }
									className="breadcrumbs__item-link project-breadcrumbs__item-link"
									to={`/projects/${ crumb.id}`}
									state={{ project: crumb }}
								>
									<div className="breadcrumbs__item-name project-breadcrumbs__item-name">{ crumb.title }</div>
								</Link>
							</div>
						</>
					)
				})}

				<div className="breadcrumbs__item project-breadcrumbs__item">
					<Link
						className="breadcrumbs__item-link project-breadcrumbs__item-link"
						to={`/projects`}
						state={{ project: false }}
					>
						<div className="breadcrumbs__item-name project-breadcrumbs__item-name">{ props.t( 'Projects' ) }</div>
					</Link>
				</div>
			</div>
		</>
	)
}

export default ProjectBreadcrumbs;