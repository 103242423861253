import { useDispatch, useSelector } from 'react-redux';

import DisplaySuccess from './DisplaySuccess';
import DisplayWarning from './DisplayWarning';
import DisplayError from './DisplayError';

const DisplayMessage = ( props ) => {
	const dispatch        = useDispatch();
	const display_message = useSelector( state => state.display_message );

	if ( ! display_message ) {
		return '';
	}

	const snackbarHandleClose = ( event, reason ) => {
		if ( reason === 'clickaway' ) {
			return;
		}

		dispatch({ type: 'REMOVE_MESSAGES', payload: true })
	}

	if ( display_message?.type === 'success' ) {
		return(
			<DisplaySuccess
				snackbarHandleClose={ snackbarHandleClose }
				display_message={ display_message }
				{ ...props }
			/>
		)
	}

	if ( display_message?.type === 'error' ) {
		return(
			<DisplayError
				snackbarHandleClose={ snackbarHandleClose }
				display_message={ display_message }
				{ ...props }
			/>
		)
	}

	if ( display_message?.type === 'warning' ) {
		return(
			<DisplayWarning
				snackbarHandleClose={ snackbarHandleClose }
				display_message={ display_message }
				{ ...props }
			/>
		)
	}

	return '';
}

export default DisplayMessage;