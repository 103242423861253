import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../../../assets/dist/img/chrome-icon.svg';
import { PrimaryButton } from '../../buttons/PrimaryButton';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import Switch from '@mui/material/Switch';
import updateUser from '../../../api/user/update-user';

const ConnectChromeExtension = ( props ) => {
	const user     = useSelector( state => state.user );
	const dispatch = useDispatch();

	const [checked, setChecked]           = useState( user?.integrations?.chrome_extension?.status ?? false );

	const handleChange = ( e ) => {
		setChecked( e.target.checked );

		let integrations = { ...user.integrations,
			chrome_extension: {
				'status': e.target.checked
			},
		}

		let data = { ...user,
			integrations: integrations
		}

		const updateUserThunk = updateUser( data )
		dispatch(updateUserThunk)
	}

	useEffect( () => {
		if ( user ) {
			setChecked( user?.integrations?.chrome_extension?.status );
		}
	}, [ user ] );

	if ( ! user ) {
		return ''
	}

	return (
		<div className="integrations-connect__item">
			<div className="integrations-connect__item-header">
				<div className="integrations-connect__item-icon">
					<img src={ logo } />
				</div>
				<div className="integrations-connect__item-title">
					{ props.t( 'Chrome Extension' ) }
				</div>

				{ user && (
					<Switch
						checked={checked}
						onChange={handleChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				)}
			</div>
			<div className="integrations-connect__item-content">
				<div className="integrations-connect__item-description">
					{ props.t( 'Use Straact on every webpage you visit.' ) }
				</div>
			</div>
			<div className="integrations-connect__item-setup">
				<div className="integrations-connect__item-view">
					<a href="https://chrome.google.com/webstore/detail/straact/ddnpjnkahgmpioijmiepmbilbfkpjnha" target="_blank">{ props.t( 'Install' ) }</a>
				</div>
			</div>
		</div>
	)
}

export default ConnectChromeExtension;
