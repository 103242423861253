import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rings } from  'react-loader-spinner';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import cancelSubscription from '../../api/stripe/cancel-subscription';

import { PrimaryButton, PrimaryButtonDisabled } from '../buttons/PrimaryButton';
import { ThirdButton } from '../buttons/ThirdButton';

const CancelSubscriptionModal = ( props ) => {
	const user     = useSelector( state => state.user );
	const loading = useSelector( state => state.loading );
	const dispatch = useDispatch();
	const [ open, setOpen ]                 = useState( false );
	const [ feedback, setFeedback ]         = useState( '' );

	const handleOpen = () => {
		setOpen( true );
	}

	const handleClose = () => {
		setOpen( false );
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const onSubmit = ( event ) => {
		event.preventDefault();

		const cancelSubscriptionThunk = cancelSubscription(
			{
				'user': user,
				'feedback': feedback,
			}
		)

		dispatch( cancelSubscriptionThunk )
	}

	let button = '';
	if ( feedback !== '' ) {
		button = <PrimaryButton text={ props.t( 'Cancel subscription' ) } type="submit" />
	} else {
		button = <PrimaryButtonDisabled text={ props.t( 'Cancel subscription' ) } />
	}

	return (
		<>
			<ThirdButton
				className="cancel-subscription__btn"
				text={ props.t( 'Cancel my subscription' ) }
				size="small"
				onClick={ handleOpen }
			/>
			<Modal
				className="basic__modal cancel-subscription__modal"
				open={open}
				onClose={handleClose}
			>
				<Box
					className="basic__modal-box cancel-subscription__modal-box"
					sx={{ ...style, width: '100%', maxWidth: '600px' }}
					disabled={ loading }
				>
					<div className="basic__modal-title cancel-subscription__modal-title">{ props.t( 'Cancel subscription' ) }</div>
					<div className="basic__modal-description">{ props.t( 'The subscription will cancel at period end' ) }</div>

					<form
						className="sign-up__form form"
						onSubmit={ onSubmit }
					>
						<div className="form__fields">
							<TextField
								label={ props.t( "What's the reason behind leaving us?" ) }
								multiline
								variant="outlined"
								rows={4}
								maxRows={4}
								autoComplete="new-name"
								onChange={ event => setFeedback( event.target.value ) }
								required
							/>
							{ button }
						</div>
					</form>
				</Box>
			</Modal>
		</>
	)
}

export default CancelSubscriptionModal;