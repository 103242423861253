import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS, APP_ROOT } from '../../utils/constants';

const reactivateSubscription = ( params ) => {
	return async function reactivateSubscriptionThunk() {
		axios.post(
			`${API_ENDPOINTS.STRAACT_FLASK_API}/stripe/subscription/reactivate`,
			params,
			{
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		)
		.then( response => {
			if ( response.data.type === 'success' ) {
				window.location.href = `${APP_ROOT}/settings/plan?subscription_reactivated`
			}

			if ( response.data.type === 'error' ) {
				dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})
	}
}

export default reactivateSubscription;
