import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Skeleton from '@mui/material/Skeleton';
import FolderIcon from '@mui/icons-material/Folder';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ProjectsWoman from '../../assets/dist/img/projects_woman.svg';

import getAssociatedProjects from '../../api/project/get-associated-projects';
import getAssociatedContent from '../../api/content/get-associated-content';
import deleteProject from '../../api/project/delete-project';
import deleteContent from '../../api/content/delete-content';
import BeautyDatetime from '../../utils/BeautyDatetime';
import ProjectBreadcrumbs from './ProjectBreadcrumbs';

import ContentType from '../../utils/ContentType';

const DocumentList = ( props ) => {
	const { parent }                    = props;
	const dispatch                      = useDispatch();
	const associatedContent             = useSelector( state => state.associated_content );
	const associatedProjects            = useSelector( state => state.associated_projects );
	const { id }                        = useParams()

	const [ anchorElProject, setAnchorElProject ] = useState( null );
	const [ anchorElContent, setAnchorElContent ] = useState( null );
	const openProject = Boolean( anchorElProject );
	const openContent = Boolean( anchorElContent );

	const handleClickProject = ( event ) => {
		setAnchorElProject( event.currentTarget );
	};

	const handleClickContent = ( event ) => {
		setAnchorElContent( event.currentTarget );
	};

	const handleCloseProject = () => {
		setAnchorElProject( null );
	};

	const handleCloseContent = () => {
		setAnchorElContent( null );
	};

	const handleDeleteProject = () => {
		let project_id = anchorElProject.getAttribute( 'data-project_id' )

		if ( project_id ) {
			const deleteProjectThunk = deleteProject( project_id, parent )
			dispatch( deleteProjectThunk )
		}
	}

	const handleDeleteContent = () => {
		let content_id = anchorElContent.getAttribute( 'data-content_id' )

		if ( content_id ) {
			const deleteContentThunk = deleteContent( content_id, parent )
			dispatch( deleteContentThunk )
		}
	}

	useEffect(() => {
		const getAssociatedProjectsThunk = getAssociatedProjects( parent )
		dispatch( getAssociatedProjectsThunk )

		const getAssociatedContentThunk = getAssociatedContent( parent )
		dispatch( getAssociatedContentThunk )
	}, [ parent, id ]);

	if ( ! associatedProjects || ! associatedContent ) {
		return (
			<>
				<div className="document-list is-loading">
					<Skeleton variant="text" width={200} height={40} />
					<Skeleton variant="text" width={"50%"} height={50} style={{ marginTop: 20 }} />
					<Skeleton variant="text" width={"80%"} height={70} style={{ marginTop: 20 }} />
					<Skeleton variant="text" width={"100%"} height={70} style={{ marginTop: 20 }} />
					<Skeleton variant="text" width={"90%"} height={70} style={{ marginTop: 20 }} />
				</div>
			</>
		)
	}

	return (
		<div className="document-list">
			{ id && (
				<div className="doucment-list__breadcrumbs">
					<ProjectBreadcrumbs { ...props } />
				</div>
			)}
			<div className="document-list__title">{ props.t( 'All documents' ) }</div>
			<div className="document-list__sorting">
				<div className="document-list__sorting-name">{ props.t( 'Name' ) }</div>
				<div className="document-list__sorting-edited">{ props.t( 'Edited' ) }</div>
			</div>

			{ associatedProjects?.results && associatedProjects?.results.length === 0 && associatedContent && associatedContent.length === 0 && (
				<div className="document-list__none-found">
					<img className="document-list__none-found-illustration" src={ ProjectsWoman } />
					<div className="document-list__none-found-text">
						{ props.t( 'Manage projects and content' ) }
						{ id ? (
							<span>{ props.t( 'Seems like this project is empty' ) }</span>
						) : (
							<span>{ props.t( 'Seems like you have no projects or content created' ) }</span>
						)}
					</div>
				</div>
			)}

			{ associatedProjects?.results && associatedProjects?.results.length > 0 && (
				associatedProjects?.results.map( project => {
					return (
						<div className="document-list__item type--project" key={ project.id }>
							<Link
								className="document-list__item-link"
								to={`/projects/${ project.id}`}
								state={{ project: project }}
							>
								<div className="document-list__item-icon"><FolderIcon /></div>
								<div className="document-list__item-name">{ project.title !== '' ? project.title : props.t( 'Unnamed project' ) }</div>
								<div className="document-list__item-edited"><BeautyDatetime datetime={ project.updated } /></div>
							</Link>
							<div className="document-list__item-settings">
								<IconButton
									aria-label="more"
									id="long-button"
									aria-controls={openProject ? 'long-menu' : undefined}
									aria-expanded={openProject ? 'true' : undefined}
									aria-haspopup="true"
									onClick={handleClickProject}
									data-project_id={ project?.id }
								>
									<MoreHorizIcon />
								</IconButton>
								<Menu
									id="long-menu"
									MenuListProps={{
										'aria-labelledby': 'long-button',
									}}
									className="document-list__item-settings-menu"
									anchorEl={ anchorElProject }
									open={ openProject }
									onClose={ handleCloseProject }
									PaperProps={{
										style: {
											width: '175px',
											boxShadow: 'var(--card-shadow)',
											border: '1px solid #f6f6f7',
										},
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
								>
									<MenuItem
										onClick={() => handleDeleteProject()}
									>
										{ props.t( 'Delete project' ) }
									</MenuItem>
								</Menu>
							</div>
						</div>
					)
				})
			)}

			{ associatedContent && associatedContent.length > 0 && (
				associatedContent.map( content => {
					let contentType = ContentType( content.type );

					return (
						<div className={`document-list__item type--${ content.type }`} key={ content.id }>
							<Link
								className="document-list__item-link"
								to={`/editor/${ content.id}`}
								state={{ content: content }}
							>
								<div className="document-list__item-icon">
									{ contentType['icon'] }
								</div>
								<div className="document-list__item-name">{ content.title !== '' ? content.title : props.t( 'Unnamed document' ) }</div>
								<div className="document-list__item-edited"><BeautyDatetime datetime={ content.updated } /></div>
							</Link>
							<div className="document-list__item-settings">
								<IconButton
									aria-label="more"
									id="long-button"
									aria-controls={openContent ? 'long-menu' : undefined}
									aria-expanded={openContent ? 'true' : undefined}
									aria-haspopup="true"
									onClick={handleClickContent}
									data-content_id={ content?.id }
								>
									<MoreHorizIcon />
								</IconButton>

								<Menu
									id="long-menu"
									MenuListProps={{
										'aria-labelledby': 'long-button',
									}}
									className="left-panel__user-settings-menu"
									anchorEl={ anchorElContent }
									open={ openContent }
									onClose={ handleCloseContent }
									PaperProps={{
										style: {
											width: '175px',
											boxShadow: 'var(--card-shadow)',
											border: '1px solid #f6f6f7',
										},
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
								>
									<MenuItem
										onClick={() => handleDeleteContent( content?.id )}
										>
										{ props.t( 'Delete content' ) }
									</MenuItem>
								</Menu>
							</div>
						</div>
					)
				})
			)}
		</div>
	)
}

export default DocumentList;
