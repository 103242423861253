import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import getMembers from '../../../api/member/get-members';
import { getDateString } from '../../../utils/date';

import RunningMan from '../../../assets/dist/img/running_man.svg';

const MemberList = ( props ) => {
	const dispatch = useDispatch();
	const user     = useSelector( state => state.user );
	const members  = useSelector( state => state.members );

	useEffect(() => {
		if ( user && user?.type === '1' ) {
			const getMembersThunk = getMembers()
			dispatch(getMembersThunk)
		}
	}, [ user ]);

	if ( ! members ) {
		return (
			<div className="member-list is-loading">
				<Skeleton variant="text" width={200} height={40} />
				<Skeleton variant="text" width={"50%"} height={50} style={{ marginTop: 20 }} />
				<Skeleton variant="text" width={"80%"} height={70} style={{ marginTop: 20 }} />
				<Skeleton variant="text" width={"100%"} height={70} style={{ marginTop: 20 }} />
				<Skeleton variant="text" width={"90%"} height={70} style={{ marginTop: 20 }} />
			</div>
		)
	}

	return (
		<div className="member-list">
			<div className="member-list__title">{ props.t( 'All members of' ) } { user?.metadata?.company_name }</div>
			<div className="member-list__sorting">
				<div className="member-list__sorting-email">{ props.t( 'Email' ) }</div>
				<div className="member-list__sorting-usage">{ props.t( 'Usage' ) }</div>
				<div className="member-list__sorting-added">{ props.t( 'Added' ) }</div>
			</div>

			{ members && members?.length > 0 && (
				members?.map( member => {
					return (
						<div className={`member-list__item`} key={ member.uid }>
							<div className="member-list__item-link">
								<div className="member-list__item-icon"><PersonRoundedIcon /></div>
								<div className="member-list__item-email">{ member?.email }</div>
								<div className="member-list__item-usage">{ member?.usage?.characters?.spent } { props.t( 'characters spent' ) }</div>
								<div className="member-list__item-added">{ member?.created }</div>
							</div>
						</div>
					)
				})
			)}

			{ members && members?.length === 0 && (
				<div className="member-list__none-found">
					<img className="member-list__none-found-illustration" src={ RunningMan } />
					<div className="member-list__none-found-text">
						{ props.t( "You haven't added any members" ) }
					</div>
				</div>
			)}
		</div>
	)
}

export default MemberList;
