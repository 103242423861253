import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const DisplaySuccess = ( { display_message, snackbarHandleClose, t } ) => {
	return (
		<Snackbar open={ true } autoHideDuration={ 8000 } onClose={ snackbarHandleClose } anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
			<Alert onClose={snackbarHandleClose} severity="success" sx={{ width: '100%' }}>
				{ t( display_message?.reason ) }
			</Alert>
		</Snackbar>
	)
}

export default DisplaySuccess;
