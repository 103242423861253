import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const removeAPIKey = () => {
	if ( UID === undefined && ACCESS_TOKEN === undefined ) {
		return false;
	}

	return async function removeAPIKeyThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/user/remove-api-key`,
			{
				params: {
					uid: UID
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_USER', payload: response.data.user })
					dispatch({ type: 'DISPLAY_WARNING', payload: { reason: 'Removed the API-key' } })
				}

				if ( response.data.type === 'error' ) {
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})
	}
}

export default removeAPIKey;
