import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LeftPanel from '../../layouts/LeftPanel';
import TopPanel from '../../layouts/TopPanel';
import SettingsMenu from '../../components/settings/SettingsMenu';
import Usage from '../../components/settings/Usage';
import getSubscription from '../../api/stripe/get-subscription';
import PricingPlans from '../../components/billing/PricingPlans';

const PlanSettings = ( props ) => {
	const user                = useSelector( state => state.user );
	const stripe_subscription = useSelector( state => state.stripe_subscription );
	const dispatch            = useDispatch();

	useEffect(() => {
		props.setAppClasses( 'settings' )
	}, []);

	useEffect(() => {
		if ( ! stripe_subscription && user?.metadata?.customer_id ) {
			const getSubscriptionThunk = getSubscription(user?.metadata?.customer_id)
			if ( getSubscriptionThunk ) {
				dispatch(getSubscriptionThunk)
			}
		}
	}, [ stripe_subscription, user ]);

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': props.t( 'Settings' ),
						'editable': false
					}}
					description={{
						'value': props.t( "Change the company settings" ),
						'editable': false,
					}}
				/>

				<SettingsMenu {...props} />

				<div className="settings-sections">
					<div className="settings-sections__item">
						<Usage {...props} />
					</div>

					<div className="settings-sections__item">
						<PricingPlans { ...props } />
					</div>
				</div>
			</div>
		</>
	)
}

export default PlanSettings;
