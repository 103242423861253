import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const getInvoices = ( customer_id ) => {
	if ( UID === undefined && ACCESS_TOKEN === undefined ) {
		return false;
	}

	return async function getInvoicesThunk( dispatch ) {
		if ( ! customer_id ) {
			dispatch({ type: 'GET_INVOICES', payload: { data: [] } })
			return;
		}

		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/stripe/invoices/get`,
			{
				params: {
					uid: UID,
					customer_id: customer_id,
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_INVOICES', payload: response.data.invoices })
				}
			}
		})
	}
}

export default getInvoices;
