import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';
import ReceiptIcon from '@mui/icons-material/Receipt';

import getInvoices from '../../api/stripe/get-invoices';
import { getDateString } from '../../utils/date';

import RunningMan from '../../assets/dist/img/running_man.svg';

const InvoiceList = ( props ) => {
	const dispatch = useDispatch();
	const user     = useSelector( state => state.user );
	const invoices = useSelector( state => state.stripe_invoices );

	useEffect(() => {
		if ( ! invoices && user ) {
			const getInvoicesThunk = getInvoices( user?.metadata?.customer_id )
			dispatch(getInvoicesThunk)
		}
	}, [ invoices, user ]);

	if ( ! invoices ) {
		return (
			<div className="invoice-list is-loading">
				<Skeleton variant="text" width={200} height={40} />
				<Skeleton variant="text" width={"50%"} height={50} style={{ marginTop: 20 }} />
				<Skeleton variant="text" width={"80%"} height={70} style={{ marginTop: 20 }} />
				<Skeleton variant="text" width={"100%"} height={70} style={{ marginTop: 20 }} />
				<Skeleton variant="text" width={"90%"} height={70} style={{ marginTop: 20 }} />
			</div>
		)
	}

	return (
		<div className="invoice-list">
			<div className="invoice-list__title">{ props.t( 'Invoices' ) }</div>
			<div className="invoice-list__sorting">
				<div className="invoice-list__sorting-number">{ props.t( 'Number' ) }</div>
				<div className="invoice-list__sorting-amount">{ props.t( 'Amount' ) }</div>
				<div className="invoice-list__sorting-status">{ props.t( 'Status' ) }</div>
				<div className="invoice-list__sorting-date">{ props.t( 'Date' ) }</div>
			</div>

			{ invoices && invoices?.data.length > 0 && (
				invoices?.data.map( invoice => {
					return (
						<div className={`invoice-list__item type--${ invoice?.status }`} key={ invoice.id }>
							<a
								className="invoice-list__item-link"
								href={ invoice?.invoice_pdf }
								target="_blank"
							>
								<div className="invoice-list__item-icon"><ReceiptIcon /></div>
								<div className="invoice-list__item-number">#{ invoice?.number }</div>
								<div className="invoice-list__item-amount"><span style={{ textTransform: 'uppercase' }}>{ invoice?.currency }</span> { invoice?.total.toString().slice( 0, -2 ).toLocaleString() }</div>
								<div className="invoice-list__item-status">{ invoice?.status }</div>
								<div className="invoice-list__item-date">{ getDateString( invoice?.created ) }</div>
							</a>
						</div>
					)
				})
			)}

			{ invoices && invoices?.data.length === 0 && (
				<div className="invoice-list__none-found">
					<img className="invoice-list__none-found-illustration" src={ RunningMan } />
					<div className="invoice-list__none-found-text">
						{ props.t( 'You have no invoices yet' ) }
						<span>{ props.t( 'Try our' ) } <NavLink to="/settings/plan">{ props.t( 'paid plans' ) }</NavLink> { props.t( 'and explore the platform fully' ) }</span>
					</div>
				</div>
			)}
		</div>
	)
}

export default InvoiceList;
