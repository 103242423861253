import { useEffect, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UID } from '../utils/constants';

import RouteChange from './RouteChange';
import ProtectedRoute from './ProtectedRoute';
import SignUp from '../pages/SignUp';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Projects from '../pages/Projects';
import Project from '../pages/Project';
import Editor from '../pages/Editor';
import Analytics from '../pages/Analytics';
import Integrations from '../pages/Integrations';
import ShopifyIntegration from '../pages/integrations/ShopifyIntegration';
import ChromeExtensionIntegration from '../pages/integrations/ChromeExtensionIntegration';
import Billing from '../pages/Billing';
import Settings from '../pages/Settings';
import LanguageSettings from '../pages/settings/LanguageSettings';
import MembersSettings from '../pages/settings/MembersSettings';
import PlanSettings from '../pages/settings/PlanSettings';
import Help from '../pages/Help';
import Error404 from '../pages/Error404';
import Backoffice from '../pages/Backoffice';
import getUser from '../api/user/get-user';

const UserRoutes = ( props ) => {
	const dispatch    = useDispatch();
	const user        = useSelector( state => state.user );

	// Get the user
	useMemo(() => {
		if ( ! user ) {
			const getUserThunk = getUser()
			if ( getUserThunk ) {
				return dispatch(getUserThunk)
			}
		}
	}, [ UID ]);

	return (
		<>
			<RouteChange />
			<Routes>
				<Route exact path='/sign-up' element={<SignUp { ...props } />}></Route>
				<Route exact path='/login' element={<Login { ...props } />}></Route>

				<Route
					element={ <ProtectedRoute /> }
				>
					<Route exact path='/' element={<Dashboard { ...props } />} />
					<Route path='/backoffice' element={<Backoffice { ...props } />}></Route>
					<Route path='/projects' element={<Projects { ...props } />}></Route>
					<Route path='/projects/:id' element={<Project { ...props } />}></Route>
					<Route path='/editor/:id' element={<Editor { ...props } />}></Route>
					<Route path='/analytics' element={<Analytics { ...props } />}></Route>
					<Route path='/integrations' element={<Integrations { ...props } />}></Route>
					<Route path='/integrations/shopify' element={ <ShopifyIntegration { ...props } /> }></Route>
					<Route path='/integrations/chrome-extension' element={ <ChromeExtensionIntegration { ...props } /> }></Route>
					<Route path='/billing' element={<Billing { ...props } />}></Route>
					<Route path='/billing/:response/:session_id' element={<Billing { ...props } />}></Route>
					<Route path='/help' element={<Help { ...props } />}></Route>
					<Route path='/settings' exact element={<Settings { ...props } />}></Route>
					<Route path='/settings/members' element={<MembersSettings { ...props } />}></Route>
					<Route path='/settings/plan' element={<PlanSettings { ...props } />}></Route>
					<Route path='/settings/language' element={<LanguageSettings { ...props } />}></Route>
					<Route path='*' element={<Error404 {...props} />}></Route>
				</Route>
			</Routes>
		</>
	)
}

export default UserRoutes;
