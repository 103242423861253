import axios from 'axios';
import { APP_ROOT, API_ENDPOINTS, ACCESS_TOKEN } from '../../utils/constants';

import getAssociatedContent from './get-associated-content';

const deleteContent = ( content_id, parent ) => {
	return async function deleteContentThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/content/delete`,
			{
				params: {
					content_id: content_id
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_CONTENT', payload: response.data.content })

					const getAssociatedContentThunk = getAssociatedContent( parent )
					dispatch( getAssociatedContentThunk )
				}

				if ( response.data.type === 'error' ) {
					dispatch({ type: 'GET_CONTENT', payload: false })
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
					window.location.href = `${APP_ROOT}`;
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})
	}
}

export default deleteContent;
