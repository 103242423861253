import axios from 'axios';
import { APP_ROOT, API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const getContent = ( content_id ) => {
	return async function getContentThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/content/get`,
			{
				params: {
					uid: UID,
					content_id: content_id
				},
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_CONTENT', payload: response.data.content })
				}

				if ( response.data.type === 'error' ) {
					dispatch({ type: 'GET_CONTENT', payload: {} })
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
					window.location.href = `${APP_ROOT}`;
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})
	}
}

export default getContent;
