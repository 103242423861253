const editorReducer = ( state = false, action ) => {
	switch( action.type ) {
		case 'GET_EDITOR_RESPONSE' :
			return action.payload
		default:
			return state
	}
}

export default editorReducer;
