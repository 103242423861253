import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../../utils/constants';

const updateContent = ( content ) => {
	return async function updateContentThunk( dispatch ) {
		axios.post(
			`${API_ENDPOINTS.STRAACT_FLASK_API}/content/update`,
			content,
			{
				headers: {
					"Authorization" : `Bearer ${ACCESS_TOKEN}`
				}
			}
		)
		.then( response => {
			if ( response.data.type === 'success' ) {
				// dispatch({ type: 'GET_CONTENT', payload: response.data.content })
			}

			if ( response.data.type === 'error' ) {
				dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})
	}
}

export default updateContent;
