import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LeftPanel from '../layouts/LeftPanel';
import TopPanel from '../layouts/TopPanel';
import DocumentList from '../components/projects/DocumentList';
import getProject from '../api/project/get-project';

const Project = ( props ) => {
	const { id } = useParams()
	const dispatch = useDispatch();
	const location = useLocation()
	const project  = useSelector( state => state.project );

	useEffect(() => {
		props.setAppClasses( 'project' )
	}, []);

	// Runs if ID param changes e.g. page refresh
	useEffect(() => {
		if ( id ) {
			const getProjectThunk = getProject( id )
			dispatch(getProjectThunk)
		}
	}, [ id ]);

	// Runs if location changes e.g. clicking around the documents
	useEffect(() => {
		if ( location?.state?.project ) {
			dispatch({ type: 'GET_PROJECT', payload: location?.state?.project })
		}
	}, [ location?.state?.project ]);

	return (
		<>
			<LeftPanel { ...props } />

			<div className="right-panel">
				<TopPanel
					title={{
						'value': project?.title,
						'editable': true,
						'changed': project?.id
					}}
					description={{
						'value': project?.description,
						'editable': true,
						'changed': project?.id
					}}
					isProject
					{ ...props }
				/>

				<DocumentList parent={ id } { ...props } />
			</div>
		</>
	)
}

export default Project;
