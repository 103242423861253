import { useSelector } from 'react-redux';

import { default as AvatarMui } from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Skeleton from '@mui/material/Skeleton';

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: 'var(--color-1)',
		color: 'var(--color-1)',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: '-1px',
			left: '-1px',
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}));

const Avatar = () => {
	const user = useSelector( state => state.user );

	if ( ! user ) {
		return (
			<Skeleton variant="circular" width={40} height={40} />
		)
	}

	return (
		<StyledBadge
			overlap="circular"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			variant="dot"
		>
			<AvatarMui alt="Remy Sharp" src="https://dt2sdf0db8zob.cloudfront.net/wp-content/uploads/2019/12/9-Best-Online-Avatars-and-How-to-Make-Your-Own-for-Free-image1-5.png" />
		</StyledBadge>
	)
}

export default Avatar;
