import axios from 'axios';
import { API_ENDPOINTS, UID, ACCESS_TOKEN } from '../../utils/constants';

const getAssociatedContent = ( parent_id ) => {
	return async function getAssociatedContentThunk( dispatch ) {
		await axios.get(
			`${ API_ENDPOINTS.STRAACT_FLASK_API }/content/get`,
			{
				params: {
					uid: UID,
					parent: parent_id
				},
				headers: {
					"Authorization": `Bearer ${ACCESS_TOKEN}`
				}
			}
		).then( response => {
			if ( response.data ) {
				if ( response.data.type === 'success' ) {
					dispatch({ type: 'GET_ASSOCIATED_CONTENT', payload: response.data.contents })
				}

				if ( response.data.type === 'error' ) {
					dispatch({ type: 'DISPLAY_ERROR', payload: response.data })
				}
			}
		}).catch( error => {
			dispatch({ type: 'DISPLAY_ERROR', payload: error.response.data })
		})
	}
}

export default getAssociatedContent;
