const messageReducer = ( state = false, action ) => {
	if ( ! action.payload ) {
		return state;
	}

	switch( action.type ) {
		case 'DISPLAY_ERROR' :
			action.payload.type = 'error';
			return action.payload
		case 'DISPLAY_WARNING' :
			action.payload.type = 'warning';
			return action.payload
		case 'DISPLAY_SUCCESS' :
			action.payload.type = 'success';
			return action.payload
		case 'REMOVE_MESSAGES' :
			return action.payload
		default:
			return state
	}
}

export default messageReducer;
