import { Navigate, Outlet } from 'react-router-dom';
import AuthUser from '../utils/AuthUser';

const ProtectedRoute = ( { redirectPath = '/login', children } ) => {
	const AUTH = AuthUser();

	if ( ! AUTH ) {
		return <Navigate to={ redirectPath } />;
	}

	return children ? children : <Outlet />;
};

export default ProtectedRoute;
